import everestwel from "../assets/nurse/everestwel.jpg";
import Typewriter from "typewriter-effect";

const Welcome = () => {
  return (
    <div>
      <div className="  mt-5 relative    bg-gradient-to-tr from-slate-500 to-blue-600 md:h-96 h-[20vh] w-full">
        <img
          src={everestwel}
          alt=""
          className="  object-cover absolute w-full h-full mix-blend-overlay"
        />

        <div className=" flex flex-col justify-center md:items-center gap-2 md:p-10 p-5">
          <h1 className="  text-white md:text-5xl  text-2xl tracking-widest italic font-bold">
            WELCOME TO EVEREST HOSPITAL PVT.LTD
          </h1>
          <h1 className=" flex gap-1  text-white md:text-3xl text-xl font-light">
            Doctors:
            <div className="    text-white font-semibold">
              <Typewriter
                options={{
                  strings: [
                    "Prof. Dr. Chandeshwar Mahaseth",
                    "Dr. Rajan Shah",
                    "Dr Subash Khadka",
                    "Dr. Jyotshna Sharma",
                    "Dr. Sunil Shrestha",
                    "Dr. Sushil Mohan Bhattari",
                    "Prof. Dr. Ram Kishor Sah",
                    "Prof. Dr. Dilip Sharma",
                    "Dr. Akhilesh Kumar Kashyap",
                    "Dr. Kajan Raj Shrestha",
                    "Dr.Chandan K Jayswal",
                    "Dr Prerna Jha",
                    "Dr. Abasesh Bhandari",
                    " Dr. Sanat Chalise",
                    "Dr. AnupamaThapa Basnet",
                    "Dr.Naresh Giri",
                    "Dr Roshan Pokhrel",
                    " Dr. Ram Kishor Sah",
                    "Dr. Bachhu Ram KC",
                    "Dr. Nabin Pokharel",
                    "Dr. Adheep Arun Shrestha",
                    "Dr Subharna Dhoj Thapa",
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 75,
                }}
              />
            </div>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
