import { useEffect, useState, createContext } from "react";
import { AxiosInstance } from "../config";
export const CharityContext = createContext();

export const CharityContextProvider = ({ children }) => {
  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  console.log(isAuthenticated);

  // fetch all
  const [contactDetail, setContactDetail] = useState();
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/contact-details");
        setContactDetail(res.data.data[0]);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  const fetcInEveryTwoMin = async () => {
    try {
      await AxiosInstance.get("/contact-details");
      console.log("Status:", "OK");
    } catch (error) {
      console.error("Status", "Not OK");
    }
  };

  useEffect(() => {
    fetcInEveryTwoMin();
    const intervalId = setInterval(() => {
      fetcInEveryTwoMin();
    }, 120000);
    return () => clearInterval(intervalId);
  }, []);
  console.log(fetching);

  return <CharityContext.Provider value={{ contactDetail, isAuthenticated }}>{children}</CharityContext.Provider>;
};
