import { useEffect, useState } from "react";
import Heading from "../components/Heading";
import parse from "html-react-parser";
import { AxiosInstance } from "../config";
import Spinner from "../components/Spinner";

import dipak from "../assets/doctors/dipak.jpg";

export default function OurMissionVision() {
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/mission-vision");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <div className="  px-4 md:flex justify-center gap-14  items-center">
      <div className="hover:scale-105 cursor-pointer duration-500 mb-16  bg-[#CFCDCA] rounded-2xl">
        <img
          height={500}
          width={300}
          className=" cursor-pointer   rounded-2xl"
          src={dipak}
          alt=""
        />{" "}
      </div>

      <div className=" md:w-6/12">
        <Heading title="Our Mission , Visions & Goals" />
        {comittees?.map((item: any) => (
          <p
            key={item._id}
            className=" text-gray-600 mb-20 shadow-md p-4 text-justify"
          >
            {parse(item?.description || "")}{" "}
          </p>
        ))}

        {fetching && (
          <div className=" flex items-center justify-center ">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
