import React from "react";
import dipk from "../assets/processImage/dipk.jpg";
import ajya from "../assets/processImage/ajya.jpg";
import arbin from "../assets/processImage/arbin.jpg";
import chan from "../assets/processImage/chan.jpg";
// import sai from "../assets/processImage/sai.jpg";
import raj from "../assets/processImage/raj.jpg";
import pank from "../assets/processImage/pank.jpg";
// 

export default function Team() {
  return (
    <div className=" flex justify-center items-center">
      <div className=" flex justify-center items-center md:w-10/12">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 gap-14">
          {items.map((item, index) => (
            <div
              key={index}
              className=" flex flex-col gap-4 shadow-lg group hover:shadow-2xl duration-500 px-4 pb-4 rounded-md h-max"
            >
              <img
                className=" h-96 w-full object-cover cursor-pointer rounded-3xl"
                src={item.img}
                alt=""
              />
              <div className=" flex flex-col gap-2">
                <p className=" group-hover:underline duration-500 text-2xl text-red-800 font-semibold">
                  {item.name}
                </p>
                <p className=" text-gray-900  text-[18px]">{item.position}</p>
                <p className=" text-gray-700">{item.desc}</p>
                <p className="  text-gray-500"> NMC No : {item.nmc}</p>
                <p className=" mt-4 text-yellow-500  font-bold">VISITING TIME :</p>
                <button className=" bg-primary-500 hover:bg-primary-400 duration-500 text-sm text-gray-100  py-2 rounded-md ">
                {item.visitTime}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


const items = [
  {
    img: dipk,
    name: "Dr. Dipak Paudel",
    desc: "MBBS, MD (Intenal Medicine) , Fellowship in Endocrinology & Metabolism (SGRH, New Delhi,India",
    nmc: "7311",
    visitTime: "SUN,TUE,WED & FRI (4:30 PM - 6:00 PM)",
  },
  {
    img: ajya,
    name: "Dr. Ajay Parajulee",
    position: "General And Laparoscopic Surgeon",
    desc: "MBBS, MS (General Surgery) , FMAS/DMAS Training in Minimal Access Surgery(WLH india)",
    nmc: "6665",
    visitTime: "SUN-FRI ((9:00 AM -11:00 AM) ",
  },
  {
    img: pank,
    name: "Dr. Pankaj Deo",
    position: "Consultant Pulmonary,Critical Care And Sleep Medicine",
    desc: "MBBS, MD,NCCP (I)",
    nmc: "14814",
    visitTime: "SUN-FRI ((9:00 AM -11:00 AM) ",
  },
  {
    img: chan,
    name: "Dr. Chandan K Jayswal",
    position: "Consultant Orthopaedic and Hand Surgeon",
    nmc: "9702",
    visitTime: "SUNDAY-FRIDAY ((4:00 PM -6:00 PM)",
  },

  

  {
    img: arbin,
    name: "Dr. Arbind Bhusal",
    position: "Consultant  Neuropsychiatrist",
    desc: "MBBS, MD (KU)",
    nmc: "7007",
    visitTime: "SUNDAY-FRIDAY ((8:00 AM -9:00 AM)",
  },
  // {
  //   img: ban,
  //   name: "Dr. Bandana Jha",
  //   position: "Dermatology,Venereology & Leprosy",
  //   desc: "MBBS (KMCTH,KU) MD (BHU , India)",
  //   nmc: "12740",
  //   visitTime: "SUNDAY-FRIDAY ((3:00 PM -5:00 PM)",
  // },
  {
    img: raj,
    name: "Dr. Rajan Paudel",
    position: "Consultant  Cardlogogist",
    desc: "MBBS, MD (Intenal Medicine) , NAMS MD (Cardiology), NAMS/SGNHC",
    nmc: "5713",
    visitTime: "SUN-WED ((8:00 AM -9:00 AM) , MON- FRi (4:30 PM - 5:30 PM)",
  },
  // {
  //   img: sai,
  //   name: "Prof. Dr. Shailendra Shrestha",
  //   position: "Senior Consultant Nephrologist & Kidney Transplant Physician",
  //   desc: "MBBA,MD (internal medicine) , BPKISH Dharan Nepal DM (Nephrology), TUTH, IOM kathmandu Nepal",
  //   nmc: "3002",
  //   visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  // },
];
