import hero1 from "../assets/nurse/hero1.jpg";
import hero2 from "../assets/nurse/hero2.jpg";
import hero3 from "../assets/nurse/hero3.jpg";
import nur16 from "../assets/nurse/nur1.jpg";
import nur19 from "../assets/nurse/nur9.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Slider = () => {
  return (
    <div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {" "}
        {items.map((item, index) => (
          <SwiperSlide>
            <div
              key={index}
              className=" relative  bg-gradient-to-br  from-cyan-600    to-sky-200 md:h-[100vh] h-[40vh] w-full"
            >
              <img
                src={item.img}
                alt=""
                className="  object-cover absolute w-full h-full mix-blend-overlay"
              />

              <div className=" md:p-20">
                <div className=" flex flex-col md:justify-center md:items-center  md:mt-28">
                  <h1 className=" md:mt-0 mt-32 text-2xl md:px-0 px-8 uppercase tracking-wider  text-white md:text-5xl  font-bold">
                    {item.name}
                  </h1>
                  <h1 className="  animate-bounce  duration-500 md:px-0 px-8 md:mt-5 mt-2 text-xl   text-red-600 md:text-4xl  font-semibold">
                    {item.desc}
                  </h1>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;

const items = [
  {
    img: hero1,
    name: " Everests Inclusive Healthcare Hub ",
    desc: "24 hour services",
  },
  {
    img: hero2,
    name: "Everest Cares: Your Path to Wellness",
    desc: "Experienced Doctor",
  },
  {
    img: hero3,
    name: "Start Your Health Journey Here",
    desc: "In the heart of the City",
  },
  {
    img: nur19,
    name: "The Everest Hospital Pvt.Ltd",
    desc: "Clinical Excellence",
  },

  {
    img: nur16,
    name: "Bridging Health with Compassion",
    desc: "Dedicated and Trained Staff",
  },
];
