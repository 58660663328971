
import { useState, useEffect } from "react";
import { AxiosInstance } from "../config";

export default function FeaturesSection() {
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/features");
        setcomittees(res.data.data);
      } catch (error) {}
    };
    fetchAll();
  }, []);

  return (
    <section className="">
      <div className=" md:px-20 text-gray-600  ">
        <div className="relative max-w-2xl mx-auto sm:text-center">
          <div className="relative z-10">
            <h3 className="text-gray-700 text-2xl font-semibold sm:text-4xl ">Features of Everest Hospital</h3>
            <p className="mt-3">Explore Everest Hospitals advanced medical services, expert care, and commitment to affordable, inclusive healthcare for all individuals.</p>
          </div>
          <div
            className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
            style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
        </div>
        <div className="relative mt-12">
          <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {comittees.map((item: any, idx) => (
              <li
                key={idx}
                className=" hover:shadow-xl stroke-gray-200 duration-500 bg-white space-y-3 p-4 border rounded-lg">
                <img
                  src={item.image}
                  className=" h-12 w-12 rounded-lg"
                  alt="img"
                />
                <h4 className=" text-gray-700 font-semibold">{item.name}</h4>
                <p className=" text-sm text-gray-600 leading-relaxed tracking-wide">{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
