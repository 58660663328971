import { Link } from "react-router-dom";
import nur17 from "../assets/nurse/nur19.jpg";
// import CountUp from "react-countup";

const Choose = () => {
  return (
    <div className=" flex  flex-col gap-16 justify-center items-center  md:h-[100vh] h-full  md:px-0 px-4 bg-gray-100">
      <div className=" md:flex justify-between  mt-5  gap-16 items-center w-full md:w-10/12">
        <div
          data-aos="fade-down-right"
          data-aos-duration="1500"
          className="  flex   justify-center mt-8 flex-col gap-5 md:w-4/12"
        >
          <h1 className=" md:text-4xl text-2xl   text-primary-600 cursor-pointer  hover:scale-105 duration-500">
            WHY CHOOSE EVEREST{" "}
          </h1>
          <h2 className=" text-xl text-gray-600">
            Everest Hospital offers you access to extensive and top-tier medical
            care.
          </h2>
          <Link to={"/about"}>
            <button className=" bg-primary-600 text-white py-2 md:px-8  px-4 w-max hover:scale-110 duration-500">
              LEARN MORE
            </button>
          </Link>
        </div>
        <div
          data-aos="fade-up-left"
          data-aos-duration="1500"
          className=" mt-8  grid grid-cols-1 md:grid-cols-3  justify-between gap-8 md:w-6/9"
        >
          {contents.map((content, index) => (
            <div key={index} className=" flex flex-col gap-1">
              <span className=" md:text-4xl text-3xl  text-primary-600 cursor-pointer border-b-2 w-24 p-2 border-gray-300">
                {/* <CountUp
                  className=" text-xl text-white"
                  duration={4}
                  start={0}
                  end={content.id}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span
                        className=" text-3xl  text-blue-800"
                        ref={countUpRef}
                      />
                      <button className="  text-blue-800 text-3xl">+</button>
                    </div>
                  )}
                </CountUp> */}
                <span>{content.id} 
                +</span>
              </span>
              <span className=" text-xl text-gray-700 cursor-pointer">
                {content.name}
              </span>
              <span className=" text-md text-gray-600 cursor-pointer hover:text-gray-400 duration-500 ">
                {content.desc}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="  flex justify-center items-center mb-10">
        <div className=" md:flex justify-between items-center  w-full  md:w-10/12 ">
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            className=" md:w-5/12"
          >
            <img
              className=" object-cover rounded-xl cursor-pointer"
              src={nur17}
              alt=""
            />
          </div>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className=" flex flex-col gap-5 md:w-5/12"
          >
            <span className=" text-primary-600 md:text-3xl text-2xl md:mt-0 mt-10">
              PROVIDING HOMELY CARE TO PATIENTS
            </span>
            <span className=" text-sm">
              PROVIDING HOMELY CARE TO PATIENTS Everest provides an extensive
              array of medical services aimed at ensuring a comfortable and
              stress-free experience for individuals seeking care. Our adept and
              multilingual medical professionals, equipped with high-level
              training, deliver personalised and compassionate healthcare
              tailored to meet the unique needs of each patient. We facilitate
              seamless access by accepting a diverse range of international
              medical insurances and implementing direct billing arrangements.
              In Nepal, our overarching objective is to furnish you with
              world-class medical care and unwavering support.
            </span>
            <Link to={"/about"}>
              <button className=" bg-primary-600 text-white py-2 md:px-8  px-4 w-max hover:scale-110 duration-500">
                LEARN MORE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;

const contents = [
  {
    id: 6000,
    name: "One of the leading",
    desc: "healthcare provider in nepal",
  },

  {
    id: 250,
    name: "33+ Medical specialities",
    desc: "hunder one roof",
  },

  {
    id: 200,
    name: "33+ Medical specialities",
    desc: "Amenities",
  },
  {
    id: 500,
    name: "Blood Bank",
  },

  {
    id: 300000,
    name: "2 Million+ Patients Treated",
    desc: "So Far",
  },
  {
    id: 999,
    name: "2 Million+ Patients Treated",
  },
];
