import axios from "axios";

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // baseURL: "http://localhost:4000/api",
});

AxiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  // config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers.Authorization = accessToken;
  return config;
});

