
import { useEffect, useState } from "react";
import { AxiosInstance } from "../config";
import Heading from "../components/Heading";

export default function Accrediation() {
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/accrediations");
        setcomittees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  return (
    <section className="">
   
      <Heading title="Our Accrediations"/>
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">Explore our extensive list of prestigious accreditations, validating our commitment to excellence and quality in diverse fields</p>
      </div>
      <ul className="mt-16 grid gap-8 sm:grid-cols-2 ">
        {comittees?.map((item: any, idx) => (
          <div
            key={idx}
            className="border rounded-lg bg-gray-50">
            <div className="p-4">
              <div className="relative h-48 md:h-72 lg:h-96">
                <img
                  src={item?.image}
                  alt="img"
                  className="object-contain w-full h-full"
                />
              </div>
              <hr className="my-2" />
              <h4 className="text-gray-700 mt-2 text-center">{item.title}</h4>
            </div>
          </div>
        ))}
      </ul>
    </section>
  );
}
