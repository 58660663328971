import { useEffect, useState } from "react";
import parse from "html-react-parser";
import Spinner from "../components/Spinner";
import { AxiosInstance } from "../config";
import Heading from "../components/Heading";

export default function About() {
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/about-sections");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <div className=" overflow-hidden">
      <Heading title="About Us" />
      {comittees.map((item: any, index: any) => (
        <section
          key={item._id}
          className={index % 2 === 0 ? "even-section" : "odd-section"}
        >
          <div   data-aos="fade-up-left"
           data-aos-duration="1500" className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex mb-12">
            <div
              className={`lg:w-6/12 ${
                index % 2 === 0 ? "lg:order-first" : "lg:order-last"
              }`}
            >
              <p className="text-gray-600 text-justify">
                {parse(item.description || "")}
              </p>
            </div>

            <div
              data-aos="fade-down-right"
              data-aos-duration="1500"
              className={`lg:w-6/12 mt-4 lg:mt-0 ${
                index % 2 === 0 ? "lg:order-last" : "lg:order-first"
              }`}
            >
              <img
                src={item?.image}
                alt="img"
                height={1000}
                width={1000}
                className="rounded-lg"
              />
            </div>
          </div>
        </section>
      ))}

      {fetching && (
        <div className=" flex items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
