import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Faq from "./pages/Faq";
import Dashboard from "./pages/dashboard/Dashboard";
import Departments from "./pages/Departments";
import ExecutiveComittee from "./pages/ExecutiveComittee";
import Notice from "./pages/Notice";
import SingleDoctor from "./pages/SingleDoctor";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import DashComittee from "./pages/dashboard/DashComittee";
import toast, { Toaster } from "react-hot-toast";
import DashGallery from "./pages/dashboard/DashGallery";
import DashAccrediation from "./pages/dashboard/DashAccrediation";
import DashDoctor from "./pages/dashboard/DashDoctor";
import DashDoctorEdit from "./pages/dashboard/DashDoctorEdit";
import DashFaq from "./pages/dashboard/DashFaq";
import DashFaqImage from "./pages/dashboard/DashFaqImage";
import DashContactDetail from "./pages/dashboard/DashContactDetail";
import SingleExecutiveComittee from "./pages/SingleExecutiveComittee";
import DashDepartment from "./pages/dashboard/DashDepartment";
import SingleDepartment from "./pages/SingleDepartment";
import Accrediation from "./pages/Accrediation";
import Award from "./pages/Award";
import DashAward from "./pages/dashboard/DashAward";
import DashNotice from "./pages/dashboard/DashNotice";
import DashEvent from "./pages/dashboard/DashEvent";
import Event from "./pages/Event";
import SingleEvent from "./pages/SingleEvent";
import DashTestimonial from "./pages/dashboard/DashTestimonial";
import DashFeature from "./pages/dashboard/DashFeature";
import DashContactDetailEdit from "./pages/dashboard/DashContactDetailEdit";
import DashFaqEdit from "./pages/dashboard/DashFaqEdit";
import DashDepartmentEdit from "./pages/dashboard/DashDepartmentEdit";
import DashEventEdit from "./pages/dashboard/DashEventEdit";
import DashAccrediationEdit from "./pages/dashboard/DashAccrediationEdit";
import DashDepartmentAddDoctor from "./pages/dashboard/DashDepartmentAddDoctor";
import DashAwardEdit from "./pages/dashboard/DashAwardEdit";
import DashComitteeEdit from "./pages/dashboard/DashComitteeEdit";
import DashFeatureEdit from "./pages/dashboard/DashFeatureEdit";
import DashAbout from "./pages/dashboard/DashAbout";
import DashAboutEdit from "./pages/dashboard/DashAboutEdit";
import DashFacility from "./pages/dashboard/DashFacility";
import DashValue from "./pages/dashboard/DashValue";
import DashValueEdit from "./pages/dashboard/DashValueEdit";
import DashFacilityEdit from "./pages/dashboard/DashFacilityEdit";
import DashTestimonialEdit from "./pages/dashboard/DashTestimonialEdit";
import ConsultantsNew from "./pages/ConsultantsNew";
import logo from "./assets/logo.png";
import { LogOut } from "lucide-react";
import { useContext, useEffect } from "react";
import { CharityContext } from "./context/context";
import Appointment from "./pages/Appointment";
import DashAppointment from "./pages/dashboard/DashAppointment";
import HealthPackages from "./pages/HealthPackages";
import DashPackage from "./pages/dashboard/DashPackage";
import DashPackageEdit from "./pages/dashboard/DashPackageEdit";
import OurFacilities from "./components/OurFacilitiesSection";
import OurColeValues from "./pages/OurColeValues";
import OurMissionVision from "./pages/OurMissionVision";
import DashMissionVision from "./pages/dashboard/DashMissionVision";
import DashVisionEdit from "./pages/dashboard/DashVisionEdit";
import Video from "./pages/Video";
import DashVideo from "./pages/dashboard/DashVideo";

import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/dashboard");
    toast.success("Logout success");
    window.location.reload();
  };

  // useEffect(() => {
  //   const isTokenPresent = localStorage.getItem("accessToken");
  //   console.log(isTokenPresent);
  // }, []);
  
  const { isAuthenticated } = useContext(CharityContext);
  return (
    <>
      <div>
        {pathname !== "dashboard" && (
          <div className=" fixed w-full bg-white z-50">
            <Navbar />
          </div>
        )}

        {isAuthenticated && (
          <>
            {pathname === "dashboard" && (
              <div className="shadow-md p-2 flex justify-between items-center">
                <div>
                  <img src={logo} className=" w-52" alt="img" />
                  <p className=" text-lg font-bold tracking-wider uppercase text-gray-700">
                    Dashboard
                  </p>
                </div>

                <div
                  onClick={handleLogout}
                  className=" cursor-pointer mr-4  md:mr-20"
                >
                  <LogOut />
                </div>
              </div>
            )}
          </>
        )}

        <div
          className={`${pathname === "dashboard" ? "w-full md:w-9/12  mt-12 m-2 border-t": pathname==="" ?  " w-full px-4 md:px-0 pt-32 lg:pt-28 overflow-x-hidden" :" overflow-x-hidden w-full md:w-10/12 mx-auto px-4 md:px-0 pt-32 lg:pt-28"
          }`}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/image" element={<Gallery />} />

            <Route path="/video" element={<Video />} />

            <Route path="/our-facilities" element={<OurFacilities />} />

            <Route path="/our-core-values" element={<OurColeValues />} />

            <Route path="/our-mission-vision" element={<OurMissionVision />} />
            <Route path="/faq" element={<Faq />} />

            <Route path="/health-packages" element={<HealthPackages />} />

            <Route path="/appointment/:id" element={<Appointment />} />

            <Route path="/events" element={<Event />} />

            <Route path="/events/:id" element={<SingleEvent />} />

            <Route
              path="/executive-comittee/:id"
              element={<SingleExecutiveComittee />}
            />

            <Route path="/departments" element={<Departments />} />
            {/* <Route
              path="/consultants"
              element={<Consultants />}
            /> */}

            <Route path="/consultants" element={<ConsultantsNew />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/executive-comittee" element={<ExecutiveComittee />} />

            <Route path="/consultants/:id" element={<SingleDoctor />} />

            <Route path="/departments/:id" element={<SingleDepartment />} />

            <Route path="/accrediations" element={<Accrediation />} />

            <Route path="/awards" element={<Award />} />

            <Route path="/notices" element={<Notice />} />

            <Route path="/dashboard" element={<Dashboard />} />

            {isAuthenticated && (
              <>
                <Route path="/dashboard/comittees" element={<DashComittee />} />

                <Route
                  path="/dashboard/comittees/edit/:id"
                  element={<DashComitteeEdit />}
                />

                <Route path="/dashboard/galleries" element={<DashGallery />} />

                <Route
                  path="/dashboard/accrediations"
                  element={<DashAccrediation />}
                />

                <Route
                  path="/dashboard/accrediations/edit/:id"
                  element={<DashAccrediationEdit />}
                />

                <Route path="/dashboard/doctors" element={<DashDoctor />} />

                <Route
                  path="/dashboard/doctors/edit/:id"
                  element={<DashDoctorEdit />}
                />

                <Route path="/dashboard/faqs" element={<DashFaq />} />

                <Route
                  path="/dashboard/faqs/edit/:id"
                  element={<DashFaqEdit />}
                />

                <Route
                  path="/dashboard/faqs/faqs-images"
                  element={<DashFaqImage />}
                />

                <Route
                  path="/dashboard/contacts"
                  element={<DashContactDetail />}
                />

                <Route
                  path="/dashboard/contacts/edit/:id"
                  element={<DashContactDetailEdit />}
                />

                <Route
                  path="/dashboard/departments"
                  element={<DashDepartment />}
                />

                <Route
                  path="/dashboard/departments/edit/:id"
                  element={<DashDepartmentEdit />}
                />

                <Route
                  path="/dashboard/departments/add-doctors/:id"
                  element={<DashDepartmentAddDoctor />}
                />

                <Route path="/dashboard/awards" element={<DashAward />} />

                <Route
                  path="/dashboard/awards/edit/:id"
                  element={<DashAwardEdit />}
                />

                <Route path="/dashboard/notices" element={<DashNotice />} />

                <Route path="/dashboard/events" element={<DashEvent />} />

                <Route
                  path="/dashboard/events/edit/:id"
                  element={<DashEventEdit />}
                />

                <Route
                  path="/dashboard/testimonials"
                  element={<DashTestimonial />}
                />

                <Route
                  path="/dashboard/testimonials/edit/:id"
                  element={<DashTestimonialEdit />}
                />

                <Route path="/dashboard/features" element={<DashFeature />} />

                <Route
                  path="/dashboard/features/edit/:id"
                  element={<DashFeatureEdit />}
                />

                <Route path="/dashboard/abouts" element={<DashAbout />} />

                <Route
                  path="/dashboard/abouts/edit/:id"
                  element={<DashAboutEdit />}
                />

                <Route
                  path="/dashboard/facilities"
                  element={<DashFacility />}
                />

                <Route
                  path="/dashboard/facilities/edit/:id"
                  element={<DashFacilityEdit />}
                />

                <Route path="/dashboard/values" element={<DashValue />} />

                <Route
                  path="/dashboard/values/edit/:id"
                  element={<DashValueEdit />}
                />

                <Route
                  path="/dashboard/appointments"
                  element={<DashAppointment />}
                />

                <Route path="/dashboard/packages" element={<DashPackage />} />

                <Route
                  path="/dashboard/packages/edit/:id"
                  element={<DashPackageEdit />}
                />

                <Route
                  path="/dashboard/vision"
                  element={<DashMissionVision />}
                />

                <Route
                  path="/dashboard/vision/edit/:id"
                  element={<DashVisionEdit />}
                />

                <Route path="/dashboard/video" element={<DashVideo />} />
              </>
            )}
          </Routes>
        </div>
        <Toaster position="top-right" reverseOrder={false} />

        {pathname !== "dashboard" && (
          <div className="pt-20 ">
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default App;
