import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation } from "react-router-dom";
import LeftBar from "../../components/dashboard/LeftBar";
import { Minus, MoveLeft, Plus } from "lucide-react";
import toast from "react-hot-toast";
import { AxiosInstance } from "../../config";
import Spinner from "../../components/Spinner";

export default function DashDepartmentAddDoctor() {
  const location = useLocation();
  const departmentId = location.pathname.split("/")[4];
  const [refetch, setRefetch] = useState(0);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        setRefetch(0);
        const res = await AxiosInstance.get(`/departments/${departmentId}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [departmentId, refetch]);

  const [adding, setAdding] = useState(false);
  const handleAddDoctor = async (doctorId: string, e: any) => {
    e.preventDefault();
    try {
      setAdding(true);
      const res = await AxiosInstance.post("/doctors-departments", {
        doctor: doctorId,
        department: departmentId,
      });
      setAdding(false);
      res.data.success && toast.success(res.data.msg);
      setRefetch(2);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setAdding(false);
    }
  };

  const [doctors, setDoctors] = useState([]);
  useEffect(() => {
    const fetAllDoctors = async () => {
      try {
        setRefetch(0);
        const res = await AxiosInstance.get("/doctors/");
        setDoctors(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetAllDoctors();
  }, []);

  const [departmentDoctors, setDepartmentDoctors] = useState([]);

  useEffect(() => {
    const fetAllDepartmentDoctors = async () => {
      try {
        setRefetch(0);
        const res = await AxiosInstance.get(`/doctors-departments/doctors-by-department/${departmentId}`);
        setDepartmentDoctors(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetAllDepartmentDoctors();
  }, [departmentId, refetch]);

  console.log(departmentDoctors);

  //delete
  const [deleting, setDeleting] = useState(false);
  const handleRemoveDoctor = async (id: string) => {
    try {
      setDeleting(true);
      const res = await AxiosInstance.delete(`/doctors-departments/${id}`);
      res.data.success && toast.success(res.data.msg);
      setDeleting(false);
      setRefetch(2);
    } catch (error) {
      setDeleting(false);
    }
  };

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4 flex flex-col gap-8">
          <Link
            to={"/dashboard/departments"}
            className=" flex items-center gap-1  text-sm">
            <MoveLeft /> Back
          </Link>
          <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Add / Remove Doctors From Department</h3>

          {singleItem && (
            <div className="">
              <div className="mt-1 text-sm leading-relaxed  text-gray-500">
                <div className=" grid grid-cols-2 gap-4">
                  <div>
                    {departmentDoctors?.length <= 0 && <p>No any doctor added in this department yet !</p>}

                    {departmentDoctors.map((item: any, index: number) => (
                      <div
                        key={index}
                        className=" flex items-center gap-4">
                        <div className=" mb-4 w-full ">
                          <p className="mb-2 ">
                            {index + 1}. {item?.doctor?.name}
                          </p>
                          <hr />
                        </div>

                        {deleting ? (
                          <Spinner />
                        ) : (
                          <Minus
                            onClick={(e) => handleRemoveDoctor(item?._id)}
                            size={24}
                            className=" cursor-pointer p-1 bg-gray-100 hover:bg-primary-100 rounded-full hover:text-white transition-all ease-in-out  "
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  <div>
                    {doctors.map((item: any, index: number) => (
                      <div
                        key={index}
                        className=" flex items-center gap-4">
                        <div className=" mb-4 w-full ">
                          <p className="mb-2 ">
                            {index + 1}. {item.name}
                          </p>
                          <hr />
                        </div>

                        {adding ? (
                          <Spinner />
                        ) : (
                          <Plus
                            onClick={(e) => handleAddDoctor(item._id, e)}
                            size={24}
                            className=" cursor-pointer p-1 bg-gray-100 hover:bg-primary-100 rounded-full hover:text-white transition-all ease-in-out  "
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
