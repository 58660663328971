import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "react-quill/dist/quill.snow.css";

import Spinner from "../components/Spinner";
import { formatDate } from "../helper/formatDate";
import { AxiosInstance } from "../config";
import Heading from "../components/Heading";

export default function Notice() {
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/notices");
        setcomittees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  // get single
  const [image, setImage] = useState<any>();
  const fetchSingle = async (id: string) => {
    try {
      const res = await AxiosInstance.get(`/notices/${id}`);
      setImage(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="">
     
      <Heading title="Our Latest Notices"/>
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">Stay informed with our latest notices, keeping you updated and engaged.</p>
      </div>
      <ul className="mt-16 grid gap-8 sm:grid-cols-2 ">
        {comittees?.map((item: any, idx) => (
          <div
            key={idx}
            className="border rounded-lg bg-gray-50">
            <Dialog.Root>
              <Dialog.Trigger
                onClick={() => fetchSingle(item._id)}
                className="y-2 px-3 font-medium text-blue-600 hover:text-blue-500 duration-150 hover:bg-gray-50 rounded-lg  ">
                <div className="p-4 ">
                  <div className="relative h-48 md:h-72 lg:h-96 ">
                    <img
                      src={item?.image}
                      alt="img"
                      className="object-contain w-full h-full "
                    />
                  </div>
                  <hr className="my-2" />
                  <h4 className="text-gray-700 mt-2 text-center">{formatDate(item.createdAt)}</h4>
                </div>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
                <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-5xl mx-auto px-4">
                  <div className="bg-white rounded-md shadow-lg px-4 py-6">
                    <Dialog.Description className="mt-1 text-sm leading-relaxed  text-gray-500">
                      {image?.image ? (
                        <div>
                          <div className="relative h-48 md:h-72 lg:h-96 mt-20  ">
                            <img
                              src={image?.image}
                              alt="img"
                              className="object-contain w-full h-full"
                            />
                          </div>

                          <p className=" font-medium my-2 tracking-wide text-center">{image.title}</p>
                        </div>
                      ) : (
                        <div className=" flex items-center justify-center">
                          <Spinner />
                        </div>
                      )}
                    </Dialog.Description>

                    <div className="items-center gap-2 mt-14 text-sm sm:flex">
                      <Dialog.Close
                        asChild
                        className=" w-full">
                        <button
                          className="w-full mt-2 p-2.5 flex-1  bg-primary-500 text-white rounded-md outline-none border  "
                          aria-label="Close">
                          Close
                        </button>
                      </Dialog.Close>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        ))}
      </ul>
    </section>
  );
}
