import { Facebook, Mail, MapPin, Youtube } from "lucide-react";
import logo from "../assets/logoev.jpg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-primary-500 text-white ">
      <footer className=" py-5 w-full md:w-9/12 mx-auto px-4 md:px-0   ">
        <div className="gap-6 justify-between md:flex">
          <div className="w-full sm:w-5/12">
            <div className="w-full">
              <div></div>
              <Link
                to={"/"}
                className=" flex gap-2  items-center"
              >
                <img src={logo} alt="logo" className=" h-9  rounded-md" />
                <h1 className=" text-gray-100  font-serif italic">
                  EVEREST HOSPITAL PVT.LTD
                </h1>
              </Link>
              <p className="leading-relaxed mt-2 ">
                We at Everest Hospital are dedicated to providing best possible
                health care at affordable prices.
              </p>
            </div>

            <div className="mt-6 ">
              <ul className="flex items-center space-x-4">
                <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/people/Everest-Hospital/100054280850242/"
                  >
                    <Facebook />
                  </Link>
                </li>
                <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                  <Link
                    target="_blank"
                    to="https://www.youtube.com/@everesthospital4060"
                  >
                    <Youtube />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className=" w-full mt-10 space-y-6 items-center justify-between sm:flex md:space-y-0 md:mt-0">
            {footerNavs.map((item, idx) => (
              <ul className="space-y-4" key={idx}>
                <h4 className="text-white font-medium text-lg">{item.label}</h4>

                {item.items.map((el: any, idx: number) => (
                  <li key={idx}>
                    <Link
                      target={
                        el.type === "location" ||
                        el.type === "youtube" ||
                        el.type === "facebook" ||
                        el.type === "mail"
                          ? "_blank"
                          : ""
                      }
                      to={
                        el.type === "mail"
                          ? `mailto:${el.name}`
                          : el.type === "phone"
                          ? `tel:${el.name}`
                          : el.href
                      }
                      className="hover:underline  flex items-center gap-1"
                    >
                      {el.icon} {el.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className=" pt-20">
          <hr />
          <div className=" text-center py-4 ">
            &copy; 2022 Everest Hospital pvt. ltd. All rights reserved.
          </div>
        </div>
        <p className=" text-xs text-end">
          {" "}
          Developed by –{" "}
          <Link
            className=" cursor-pointer hover:underline"
            target="_blank"
            to={"http://www.cloudsnepalweb.com/"}
          >
            Clouds Nepal Web
          </Link>
        </p>
      </footer>
    </div>
  );
}

const footerNavs = [
  {
    label: "Resources",
    items: [
      {
        href: "/consultants",
        name: "Consultants",
      },
      {
        href: "/executive-comittee",
        name: "Comittee",
      },

      {
        href: "/faq",
        name: "FAQ",
      },
    ],
  },
  {
    label: "Quick Links",
    items: [
      {
        href: "/",
        name: "Home",
      },

      {
        href: "/about",
        name: "About",
      },
      {
        href: "/contact",
        name: "Contact",
      },
      {
        href: "/gallery",
        name: "Gallery",
      },
    ],
  },
  {
    label: "Contact Us",
    items: [
      {
        icon: <MapPin size={18} />,
        href: "/",
        name: "New Baneshwor, Kathmandu",
        type: "location",
      },

      {
        icon: <Mail size={18} />,
        name: "everesthospital@gmail.com",
        type: "mail",
      },
      {
        icon: <Facebook size={18} />,
        href: "https://www.facebook.com/people/Everest-Hospital/100054280850242/",
        name: "Follow us on facebook",
        type: "facebook",
      },

      {
        icon: <Youtube size={18} />,
        href: "https://www.youtube.com/@everesthospital4060",
        name: "Subscribe us on youtube",
        type: "youtube",
      },
    ],
  },
];
