import { Link } from "react-router-dom";
import get from "../assets/nurse/get.jpg";

const Started = () => {
  return (
    <div className=" ">
      <div className="  overflow-hidden bg-[#4950D5]  md:px-20  px-4  py-12  md:flex items-center justify-between">
        <div
          data-aos="fade-up"
          data-aos-duration="3000"
          className="  flex flex-col gap-8  w-full md:w-6/12 "
        >
          <div className=" md:mt-0 mt-10 text-xl  md:text-2xl  text-gray-300 cursor-pointer">
            GET STARTED
          </div>
          <div className=" text-3xl  md:text-5xl text-gray-100 cursor-pointer">
            The Most Interesting Hospital in the Nepal
          </div>
          <div className=" text-xl text-gray-200">
            Everest Hospital: Central, accessible healthcare, affordable and
            integrated services. State-of-the-art facilities, experienced
            physicians create a warm environment for comprehensive care.
          </div>
          <div className=" md:flex gap-6">
            <button className=" bg-gray-100 text-gray-600 px-10  border-2 border-gray-400  hover:scale-110 duration-500 py-2 rounded-full">
              01-4795177
            </button>
            <Link to={"/contact"}>
              <button className="  md:my-0 my-5 bg-[#4950D5] text-gray-100 border-2 border-gray-400 px-10  hover:scale-110 duration-500 py-2 rounded-full">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" w-full md:w-6/12 flex justify-end"
        >
          <img
            className=" object-cover rounded-2xl hover:rotate-3 duration-500 cursor-pointer"
            height={500}
            width={500}
            src={get}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Started;
