import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { AxiosInstance } from "../config";
import Heading from "../components/Heading";

export default function Video() {
  // fetch all
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/videos");
        setImages(res.data.data);
      } catch (error) {}
    };
    fetchAll();
  }, []);

  return (
    <>
      <section>
        <Heading title="Videos" />
        <p className="w-full  lg:w-6/12 text-gray-600 mt-2 tracking-wide leading-normal">Explore our comprehensive videos showcasing hospital facilities, dedicated staff, and impactful moments in healthcare excellence.</p>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
          {images?.map((item: any) => (
            <div
              key={item._id}
              className=" bg-gray-300">
              <iframe
                src={item.url}
                className=" w-full h-80"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
