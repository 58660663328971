import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

import { formatDateTime } from "../../helper/formatDateTime";
import LeftBar from "../../components/dashboard/LeftBar";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../../config";

export default function DashContactDetail() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
  } = useForm();
  const allInputField = watch();
  const [refetch, setRefetch] = useState(0);

  const [creating, setCreating] = useState(false);
  const handleCreate = async () => {
    try {
      setCreating(true);
      const res = await AxiosInstance.post("/contact-details", allInputField);
      res.data.success && toast.success(res.data.msg);
      setCreating(false);
      reset();
      setRefetch(1);
    } catch (error: any) {
      console.error(error);
      setCreating(false);
      toast.error(error.message);
    }
  };

  // fetch all
  const [comittees, setcomittees] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setRefetch(0);
        setFetching(true);
        const res = await AxiosInstance.get("/contact-details");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, [refetch]);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  const fetchSingle = async (id: any) => {
    try {
      const res = await AxiosInstance.get(`/contact-details/${id}`);
      setSingleItem(res.data.data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  console.log(singleItem);

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4">
          <div className=" ">
            <div className="items-start justify-between md:flex">
              <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Contact Details</h3>
              <div className="mt-3 md:mt-0">
                {/* Create Model start---------------------------------------------- */}
                <Dialog.Root>
                  {comittees?.length <= 0 && <Dialog.Trigger className="w-32 py-2 ml-2 shadow-sm rounded-md bg-primary-600 text-white mt-4 flex items-center justify-center">New Contact</Dialog.Trigger>}
                  <Dialog.Portal>
                    <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
                    <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-5xl mx-auto px-4">
                      <form
                        onSubmit={handleSubmit(handleCreate)}
                        className="bg-white rounded-md shadow-lg px-4 py-6">
                        <Dialog.Title className="text-lg font-medium text-gray-800 text-center mt-3"> New Contact</Dialog.Title>
                        <Dialog.Description className="mt-1 text-sm leading-relaxed  text-gray-500">
                          <div className=" grid grid-cols-2 gap-4">
                            <div>
                              <label className="font-medium text-gray-700 ">Emergency Hotline</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("emergencyHotline", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Emergency Ambulance Hotline </label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("emergencyAmbulanceHotline", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Hospital Number</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("hospitalNumber", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Heli Rescue Number</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("heliRescueNumber", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Appointmnt Number</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("appointmentNumber", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Adminintration Number</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("administratioinNumber", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">24 Hour Available Number</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("twentyfourHourAvailableNumber", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Email</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("email", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium text-gray-700 ">Location</label>
                              <input
                                type="text"
                                required
                                placeholder=""
                                {...register("location", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>
                          </div>
                        </Dialog.Description>

                        <div className="items-center gap-2 mt-14 text-sm sm:flex">
                          <div className=" w-6/12">
                            <button
                              disabled={creating}
                              type="submit"
                              className="w-full mt-2 p-2.5 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                              {creating ? <Spinner /> : "Create"}
                            </button>
                          </div>
                          <Dialog.Close
                            asChild
                            className=" w-full">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-primary-600 focus:ring-2"
                              aria-label="Close">
                              Cancel
                            </button>
                          </Dialog.Close>
                        </div>
                      </form>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>

                <Link
                  to={`/dashboard/contacts/edit/${comittees[0]?._id}`}
                  onClick={() => fetchSingle(comittees[0]?._id)}
                  className="w-32 py-2 ml-2 shadow-sm rounded-md bg-primary-600 text-white mt-4 flex items-center justify-center">
                  Edit
                </Link>
                {/* Model end-------------------------------------------------------- */}
              </div>
            </div>
            <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
              <div className="w-full table-auto text-sm text-left">
                <div className="text-gray-600   ">
                  {comittees?.map((item: any, idx: any) => (
                    <div
                      key={idx}
                      className="grid grid-cols-2 gap-8 p-8">
                      <div>
                        <th className=" px-6 whitespace-nowrap">Emergency Hotline</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.emergencyHotline}</p>
                      </div>

                      <div>
                        <th className=" px-6 whitespace-nowrap">Emergency Ambulance Hotline</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.emergencyAmbulanceHotline}</p>
                      </div>

                      <div>
                        <th className=" px-6 whitespace-nowrap">Hospital Number</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.hospitalNumber}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">Heli Rescue Number</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.heliRescueNumber}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">Appointmnt Number</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.appointmentNumber}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">Adminintration Number</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.administratioinNumber}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">24 Hour Available Number</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.twentyfourHourAvailableNumber}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">Email</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.email}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">Location</th>
                        <p className="px-6  whitespace-nowrap mt-1">{item.location}</p>
                      </div>
                      <div>
                        <th className=" px-6 whitespace-nowrap">Created At</th>
                        <p className="px-6  whitespace-nowrap mt-1">{formatDateTime(item.createdAt)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=" flex items-center justify-center my-5">{fetching && <Spinner />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
