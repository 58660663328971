import nurse from "../assets/features/nurse.svg";
import machine from "../assets/features/machine.svg";
import price from "../assets/features/price.svg";
import care from "../assets/features/care.svg";
import Specialties from "../assets/features/Specialties.svg";
import excellence from "../assets/features/excellence.svg";
import nur13 from "../assets/nurse/nur16.jpg";

export default function WhyEverestHospital() {
  return (
    <section className="mt-24">
      <div className=" md:px-20  text-gray-600 gap-16 justify-between  lg:flex">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <div className="max-w-xl space-y-3">
            <p className="text-gray-800 text-2xl font-semibold sm:text-3xl">
              Why Everest hospital?
            </p>
          </div>
          <ul className=" flex flex-col gap-4 mt-8">
            {features.map((item, idx) => (
              <li key={idx} className="">
                <div className="flex items-center gap-1">
                  <img src={item.icon} alt="img" className=" h-6 w-6" />
                  <h4 className="">{item.title}</h4>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full lg:w-6/12 mt-12 lg:mt-0">
          <img
            src={nur13}
            alt="img"
            height={800}
            width={800}
            className="  hover:animate-pulse rounded-se-3xl rounded-sm "
          />
        </div>
      </div>
    </section>
  );
}

export const features = [
  {
    icon: care,
    title: "Parent Care",
    desc: "One of the experienced private hospitals in Nepal",
  },
  {
    icon: Specialties,
    title: "Quality of Doctor",
    desc: "Laparoscopic Service    ",
  },

  {
    icon: nurse,
    title: "Dedicated and  Trained Staff",
    desc: "Equal treatment for everyone; special discounted prices for impoverished and deprived people.",
  },

  {
    icon: machine,
    title: "Advance Technology",
    desc: "Highly experienced, skilled Dedicated nursing and paramedical support, exclusive patient advice, and liaison team.",
  },

  {
    icon: excellence,
    title: "Clinical Excellence",
    desc: "Socially conscious organization ",
  },

  {
    icon: price,
    title: "Affortable Price",
    desc: "Best Center For Gastro    ",
  },
];
