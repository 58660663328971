import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

import LeftBar from "../../components/dashboard/LeftBar";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../../config";

export default function DashTestimonial() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
  
  } = useForm();
  const allInputField = watch();
  const [refetch, setRefetch] = useState(0);

  const [creating, setCreating] = useState(false);
  const handleCreate = async () => {
    try {
      setCreating(true);
      const res = await AxiosInstance.post("/testimonials", allInputField);
      res.data.success && toast.success(res.data.msg);
      setCreating(false);
      reset();
      setRefetch(1);
    } catch (error: any) {
      console.error(error);
      setCreating(false);
      toast.error(error.message);
    }
  };

  //delete
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async (id: string, idx: number) => {
    try {
      setDeleteSuccess(false);
      setDeleting(true);
      const res = await AxiosInstance.delete(`/testimonials/${id}`);
      res.data.success && toast.success(res.data.msg);
      setDeleteSuccess(true);
      setDeleting(false);
      setRefetch(2);
    } catch (error) {
      setDeleteSuccess(false);
      setDeleting(false);
    }
  };

  // fetch all
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setRefetch(0);
        setFetching(true);
        const res = await AxiosInstance.get("/testimonials");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, [refetch]);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  const fetchSingle = async (id: string) => {
    try {
      const res = await AxiosInstance.get(`/testimonials/${id}`);
      setSingleItem(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(deleteSuccess)

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4">
          <div className=" ">
            <div className="items-start justify-between md:flex">
              <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Testimonials</h3>
              <div className="mt-3 md:mt-0">
                {/* Create Model start---------------------------------------------- */}
                <Dialog.Root>
                  <Dialog.Trigger className="w-32 py-2 ml-2 shadow-sm rounded-md bg-primary-600 text-white mt-4 flex items-center justify-center">New</Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
                    <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-5xl mx-auto px-4">
                      <form
                        onSubmit={handleSubmit(handleCreate)}
                        className="bg-white rounded-md shadow-lg px-4 py-6">
                        <Dialog.Title className="text-lg font-medium text-gray-800 text-center mt-3"> Create New Testimonial</Dialog.Title>
                        <Dialog.Description className="mt-1 text-sm leading-relaxed  text-gray-500">
                          <div className=" flex flex-col gap-4">
                            <div>
                              <label className="font-medium ">Name</label>
                              <input
                                type="text"
                                required
                                placeholder="Suraj Dhakal"
                                {...register("name", { required: true })}
                                className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>

                            <div>
                              <label className="font-medium ">Description</label>
                              <textarea
                                required
                                placeholder="Write Review Here"
                                {...register("description", { required: true })}
                                className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>
                          </div>
                        </Dialog.Description>

                        <div className="items-center gap-2 mt-14 text-sm sm:flex">
                          <div className=" w-6/12">
                            <button
                              disabled={creating}
                              type="submit"
                              className="w-full mt-2 p-2.5 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                              {creating ? <Spinner /> : "Create"}
                            </button>
                          </div>
                          <Dialog.Close
                            asChild
                            className=" w-full">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-primary-600 focus:ring-2"
                              aria-label="Close">
                              Cancel
                            </button>
                          </Dialog.Close>
                        </div>
                      </form>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
                {/* Model end-------------------------------------------------------- */}
              </div>
            </div>
            <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
              <table className="w-full table-auto text-sm text-left">
                <thead className=" text-gray-600 font-medium border-b">
                  <tr>
                    <th className="py-3 px-6">S.N</th>
                    <th className="py-3 px-6">Full Name</th>
                    <th className="py-3 px-6">Description</th>
                    <th className="py-3 px-6">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 divide-y">
                  {comittees?.map((item: any, idx: any) => (
                    <tr key={idx}>
                      <td className="px-6 py-4 whitespace-nowrap">{idx + 1} .</td>

                      <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.description.slice(0, 70)}</td>
                      <td className=" px-6 whitespace-nowrap">
                        <Dialog.Root>
                          <Dialog.Trigger
                            onClick={() => fetchSingle(item._id)}
                            className="y-2 px-3 font-medium text-blue-600 hover:text-blue-500 duration-150 hover:bg-gray-50 rounded-lg  ">
                            View
                          </Dialog.Trigger>
                          <Dialog.Portal>
                            <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
                            <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-5xl mx-auto px-4">
                              <div className="bg-white rounded-md shadow-lg px-4 py-6">
                                <Dialog.Description className="mt-1 text-sm leading-relaxed  text-gray-500">
                                  <div className=" grid grid-cols-2 gap-4">
                                    <div>
                                      <p className=" font-medium text-lg text-gray-700">Full Name:</p>
                                      <p>{singleItem?.name}</p>
                                    </div>
                                  </div>

                                  <div className="mt-2">
                                    <p className=" font-medium text-lg text-gray-700">Description:</p>
                                    <p className="custom-list-styles">{singleItem?.description}</p>
                                  </div>
                                </Dialog.Description>

                                <div className="items-center gap-2 mt-14 text-sm sm:flex">
                                  <Dialog.Close
                                    asChild
                                    className=" w-full">
                                    <button
                                      className="w-full mt-2 p-2.5 flex-1  bg-primary-500 text-white rounded-md outline-none border  "
                                      aria-label="Close">
                                      Close
                                    </button>
                                  </Dialog.Close>
                                </div>
                              </div>
                            </Dialog.Content>
                          </Dialog.Portal>
                        </Dialog.Root>

                        <Link
                          to={`/dashboard/testimonials/edit/${item._id}`}
                          className="y-2 px-3 font-medium text-yellow-600 hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg  ">
                          Edit
                        </Link>

                        <button
                          onClick={() => handleDelete(item._id, idx)}
                          disabled={item.deleting}
                          className="py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg">
                          {deleting ? <Spinner /> : "Delete"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className=" flex items-center justify-center my-5">{fetching && <Spinner />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
