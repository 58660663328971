import Choose from "../components/Choose";
import FeaturesSection from "../components/FeatureSection";
import HealthCare from "../components/HealthCare";
import HelpLine from "../components/HelpLine";
// import HeroSection from "../components/HeroSection";
import Information from "../components/Information";
import Process from "../components/Process";
import Slider from "../components/Slider";
import Started from "../components/Started";
import Team from "../components/Team";
// import Teams from "../components/Teams";
import Testimonials from "../components/Testimonials";
import Welcome from "../components/Welcome";
import WhyEverestHospital from "../components/WhyEverestHospital";

export default function Home() {
  return (
    <div className=" flex flex-col gap-16 ">
      {/* <HeroSection /> */}
      <Slider/>
      <Welcome />
      <Team/>
      {/* <Teams /> */}
      <Choose />
      <Started />
      <Process />
      <Information />
      <HealthCare />
      <FeaturesSection />
      <HelpLine />
      <WhyEverestHospital />
      <Testimonials />
    
    </div>
  );
}
