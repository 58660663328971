import nur1 from "../assets/nurse/nur1.jpg";
import nur2 from "../assets/nurse/nur2.jpg"
import nur4 from "../assets/nurse/nur4.jpg";
import nur5 from "../assets/nurse/nur5.jpg";
import nur6 from "../assets/nurse/nur6.jpg";
import nur7 from "../assets/nurse/nur7.jpg";
import nur9 from "../assets/nurse/nur9.jpg";
import nur10 from "../assets/nurse/nur10.jpg";
import nur11 from "../assets/nurse/nur11.jpg";
import nur12 from "../assets/nurse/nur12.jpg";
import nur13 from "../assets/nurse/nur13.jpg";
import nur14 from "../assets/nurse/nur14.jpg";
import nur16 from "../assets/nurse/nur16.jpg";
import anil from "../assets/nurse/anil.jpg";
import abhi from "../assets/healthImage/abhi.jpg";
import abhi2 from "../assets/healthImage/abhi2.jpg";

import Marquee from "react-fast-marquee";

export default function HealthCare() {
  return (
    <div>
      <div className="py-14">
        <div className=" w-full">
          <div className="max-w-xl mx-auto sm:text-center">
            <h3 className="text-gray-800 text-3xl font-semibold tracking-widest sm:text-4xl">
              Health Care Solutions
            </h3>
            <p className="text-gray-600 mt-3">
              Everest Hospital: Central, accessible healthcare, affordable and
              integrated services. State-of-the-art facilities, experienced
              physicians create a warm environment for comprehensive care.
            </p>
          </div>
          <Marquee speed={20} className="mt-12">
            <div className="grid sm:grid-cols-2 md:grid-cols-4">
              {team.map((item, idx) => (
                <div key={idx}>
                  <div className="  w-full h-60 sm:h-52 md:h-72">
                    <img
                      src={item.image}
                      className=" hover:opacity-70 hover:scale-95 duration-700 w-full h-full object-cover object-center"
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
          <Marquee direction="right" speed={20}>
            <div className="grid sm:grid-cols-2 md:grid-cols-4">
              {teams.map((item, idx) => (
                <div key={idx}>
                  <div className="  w-full h-60 sm:h-52 md:h-72">
                    <img
                      src={item.image}
                      className=" hover:opacity-70 hover:scale-95 duration-700 w-full h-full object-cover object-center"
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

const team = [
  {
    image: abhi,
  },
  {
    image: anil,
  },
  {
    image: nur1,
  },
  {
    image: nur2,
  },

  {
    image: nur4,
  },
  {
    image: nur5,
  },
  {
    image: nur6,
  },
  {
    image: nur7,
  },
];

const teams = [
  {
    image: abhi2,
  },
  {
    image: nur9,
  },
  {
    image: nur10,
  },
  {
    image: nur11,
  },
  {
    image: nur12,
  },
  {
    image: nur13,
  },
  {
    image: nur14,
  },

  {
    image: nur16,
  },
];
