import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../config";
import Spinner from "../components/Spinner";
import Heading from "../components/Heading";
import { ChevronLeft, ChevronRight } from "lucide-react";

export default function ConsultantsNew() {
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [refetch, setRefetch] = useState(0);

  const [setselectedDepartmentId, setSetselectedDepartmentId] =
    useState<any>("");
  const changeConsultant = (id: string) => {
    setSetselectedDepartmentId(id);
  };

  const showAllDoctor = () => {
    setRefetch(2);
    setSetselectedDepartmentId("");
  };

  const [doctors, setDoctors] = useState([]);
  useEffect(() => {
    
    const fetAllDoctors = async () => {
      try {
        const res = await AxiosInstance.get(
          `/doctors-departments/doctors-by-department/${setselectedDepartmentId}`
        );
        const fetchedDoctors = res.data.data;

        // Sort doctors by 'nmc' in ascending order
        const sortedDoctors: any = [...fetchedDoctors].sort(
          (a: any, b: any) => a.doctor.nmc - b.doctor.nmc
        );

        // Set the sorted doctors in the state
        setDoctors(sortedDoctors);
      } catch (error) {
        console.log(error);
      }
    };
    fetAllDoctors();
  }, [setselectedDepartmentId]);
  console.log(doctors);

  // const sortDoctorsByNmc = () => {
  //   const sortedDoctors = [...doctors].sort((a: any, b: any) => a.doctor.nmc - b.doctor.nmc);
  //   setDoctors(sortedDoctors);
  // };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/doctors");
        setcomittees(res.data.data);
        setDoctors(res.data.data);
        setFetching(false);
        setRefetch(0);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, [refetch]);

  console.log(comittees);

  const navContainerRef = useRef<any>(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleScroll = (scrollDirection: any) => {
    const container: any = navContainerRef.current;

    if (container) {
      const scrollAmount = 200;
      const newScrollLeft =
        scrollDirection === "left"
          ? Math.max(container.scrollLeft - scrollAmount, 0)
          : Math.min(
              container.scrollLeft + scrollAmount,
              container.scrollWidth - container.clientWidth
            );

      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });

      setScrollLeft(newScrollLeft);
    }
  };
  console.log(scrollLeft);

  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get(
          "/departments/get-name-and-slug-of-all-department"
        );

        setDepartments(res.data.data);
      } catch (error) {
        console.log(error);
      }
      
    };
    fetchAll();
  }, []);

  return (
    <div className=" ">
      {/* <button onClick={sortDoctorsByNmc}>Sort by NMC</button> */}

      <Heading title="Consultants" />

      <div className="border-b border-gray-200">
        <div className="flex items-center gap-4">
          <button
            className="scroll-button left mb-4 bg-primary-300 rounded-full text-white "
            onClick={() => handleScroll("left")}
          >
            <ChevronLeft size={30} />
          </button>
          <div
            className="flex gap-5 overflow-x-scroll"
            aria-label="Tabs"
            ref={navContainerRef}
            onScroll={() => setScrollLeft(navContainerRef.current.scrollLeft)}
          >
            <p
              onClick={showAllDoctor}
              className={`${
                setselectedDepartmentId === ""
                  ? "shrink-0 border-b-2 border-primary-500 px-1 text-sm font-medium text--600"
                  : "cursor-pointer shrink-0 border-b-2 border-sky-500 border-transparent px-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              }`}
            >
              All
            </p>

            {departments?.map((item: any, index) => (
              <p
                key={index}
                className={`${
                  item._id === setselectedDepartmentId
                    ? "shrink-0 border-b-2 border-primary-500 px-1 text-sm font-medium text--600"
                    : "cursor-pointer shrink-0 border-b-2 border-sky-500 border-transparent px-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`}
                onClick={() => changeConsultant(item._id)}
              >
                {item.name}
              </p>
            ))}
          </div>
          <button
            className="scroll-button left mb-4 bg-primary-300 rounded-full text-white "
            onClick={() => handleScroll("right")}
          >
            <ChevronRight size={30} />
          </button>
        </div>
      </div>

      <section className=" ">
        <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8">
          {doctors.length >= 1 &&
            setselectedDepartmentId !== "" &&
            doctors.map((item: any, index: number) => (
              <div
                key={item._id}
                className=" bg-gray-100 p-1 rounded-md border border-primary-50"
              >
                <div className="relative h-48 ">
                  <img
                    src={item?.doctor?.image}
                    alt="img"
                    className="object-contain w-full h-full "
                  />
                </div>
                <div className=" p-2">
                  <p className=" text-gray-700 font-medium">
                    {item?.doctor?.name}
                  </p>
                  <p className=" text-gray-600 text-xs mt-1">
                    ({item?.doctor?.position})
                  </p>
                  <p className=" text-gray-500 text-xs mt-1">
                    Department : {item?.doctor?.department}
                  </p>
                  {item?.doctor?.nmc && (
                    <p className=" text-gray-500 text-xs mt-1">
                      NMC No : {item?.doctor?.nmc}
                    </p>
                  )}
                  <div className=" flex justify-between mt-4">
                    <Link
                      to={`/appointment/${item?.doctor?._id}`}
                      className="px-4 py-1 text-xs text-white bg-primary-500 rounded-sm duration-150 hover:bg-white hover:text-primary-600 border hover:border-primary-600 transition-all ease-out "
                    >
                      Appointment
                    </Link>

                    <Link
                      to={`/consultants/${item?.doctor?._id}`}
                      className="px-4 py-1 text-xs   rounded-sm duration-150 border border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white transition-all ease-out  "
                    >
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
            ))}

          {setselectedDepartmentId === "" &&
            doctors.map((item: any, index: number) => (
              <div
                key={item._id}
                className=" bg-gray-100 p-1 rounded-md border border-primary-50"
              >
                <div className="relative h-48 ">
                  <img
                    src={item?.image}
                    alt="img"
                    className="object-contain w-full h-full "
                  />
                </div>
                <div className=" p-2">
                  <p className=" text-gray-700 font-medium">{item?.name}</p>
                  <p className=" text-gray-600 text-xs mt-1">
                    ({item?.position})
                  </p>
                  <p className=" text-gray-500 text-xs mt-1">
                    Department : {item?.department}
                  </p>
                  {item.nmc && (
                    <p className=" text-gray-500 text-xs mt-1">
                      NMC No : {item?.nmc}
                    </p>
                  )}
                  <div className=" flex justify-between mt-4">
                    <Link
                      to={`/appointment/${item._id}`}
                      className="px-4 py-1 text-xs text-white bg-primary-500 rounded-sm duration-150 hover:bg-white hover:text-primary-600 border hover:border-primary-600 transition-all ease-out "
                    >
                      Appointment
                    </Link>

                    <Link
                      to={`/consultants/${item._id}`}
                      className="px-4 py-1 text-xs   rounded-sm duration-150 border border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white transition-all ease-out  "
                    >
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
            ))}

          {fetching && (
            <div className=" flex items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
