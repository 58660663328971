import CoreValues from "../components/CoreValues";
import Heading from "../components/Heading";

export default function OurColeValues() {
  return (
    <div>
     <Heading title="Our Core Values " />
      <CoreValues />
    </div>
  );
}
