import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

import LeftBar from "../../components/dashboard/LeftBar";
import { Link, useLocation } from "react-router-dom";
import { MoveLeft } from "lucide-react";
import { AxiosInstance } from "../../config";

export default function DashContactDetailEdit() {
  const location1 = useLocation();
  const id = location1.pathname.split("/")[4];
  const [refetch, setRefetch] = useState(0);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        const res = await AxiosInstance.get(`/contact-details/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id, refetch]);



  const [creating, setCreating] = useState(false);
  // State for each input field
  const [emergencyHotline, setEmergencyHotline] = useState("");
  const [emergencyAmbulanceHotline, setEmergencyAmbulanceHotline] = useState("");
  const [hospitalNumber, setHospitalNumber] = useState("");
  const [heliRescueNumber, setHeliRescueNumber] = useState("");
  const [appointmentNumber, setAppointmentNumber] = useState("");
  const [administrationNumber, setAdministrationNumber] = useState("");
  const [twentyfourHourAvailableNumber, setTwentyfourHourAvailableNumber] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");

  const handleUpdate = async (e: any) => {
    e.preventDefault();
    try {
      setRefetch(0);
      setCreating(true);
      const res = await AxiosInstance.patch(`/contact-details/${id}`, {
        emergencyHotline,
        emergencyAmbulanceHotline,
        hospitalNumber,
        heliRescueNumber,
        appointmentNumber,
        administrationNumber,
        twentyfourHourAvailableNumber,
        email,
        location,
      });
      res.data.success && toast.success(res.data.msg);
      setCreating(false);
      setRefetch(3);
    } catch (error: any) {
      console.error(error);
      setCreating(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (singleItem) {
      setEmergencyHotline(singleItem.emergencyHotline || "");
      setEmergencyAmbulanceHotline(singleItem.emergencyAmbulanceHotline || "");
      setHospitalNumber(singleItem.hospitalNumber || "");
      setHeliRescueNumber(singleItem.heliRescueNumber || "");
      setAppointmentNumber(singleItem.appointmentNumber || "");
      setAdministrationNumber(singleItem.administrationNumber || "");
      setTwentyfourHourAvailableNumber(singleItem.twentyfourHourAvailableNumber || "");
      setEmail(singleItem.email || "");
      setLocation(singleItem.location || "");
    }
  }, [singleItem]);

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4 flex flex-col gap-8">
          <Link
            to={"/dashboard/contacts"}
            className=" flex items-center gap-1  text-sm">
            <MoveLeft /> Back
          </Link>
          <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Contact Details</h3>

          <form
            onSubmit={handleUpdate}
            className="">
            <div className="mt-1 text-sm leading-relaxed  text-gray-500">
              <div className=" grid grid-cols-2 gap-4">
                <div>
                  <label className="font-medium text-gray-700 ">Emergency Hotline</label>
                  <input
                    type="text"
                    value={emergencyHotline}
                    onChange={(e) => setEmergencyHotline(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Emergency Ambulance Hotline </label>
                  <input
                    type="text"
                    defaultValue={singleItem?.emergencyAmbulanceHotline}
                    value={emergencyAmbulanceHotline}
                    onChange={(e) => setEmergencyAmbulanceHotline(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Hospital Number</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.hospitalNumber}
                    value={hospitalNumber}
                    onChange={(e) => setHospitalNumber(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Heli Rescue Number</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.heliRescueNumber}
                    value={heliRescueNumber}
                    onChange={(e) => setHeliRescueNumber(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Appointmnt Number</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.appointmentNumber}
                    value={appointmentNumber}
                    onChange={(e) => setAppointmentNumber(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Adminintration Number</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.administratioinNumber}
                    value={administrationNumber}
                    onChange={(e) => setAdministrationNumber(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">24 Hour Available Number</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.twentyfourHourAvailableNumber}
                    value={twentyfourHourAvailableNumber}
                    onChange={(e) => setTwentyfourHourAvailableNumber(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Email</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Location</label>
                  <input
                    type="text"
                    defaultValue={singleItem?.location}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <span className=" text-transparent">.</span>
                  <button
                    disabled={creating}
                    type="submit"
                    className="w-full  p-2 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                    {creating ? <Spinner /> : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
