import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

import LeftBar from "../../components/dashboard/LeftBar";
import { Link, useLocation } from "react-router-dom";
import { MoveLeft } from "lucide-react";
import { AxiosInstance } from "../../config";

export default function DashFaqEdit() {
  const location1 = useLocation();
  const id = location1.pathname.split("/")[4];
  const [refetch, setRefetch] = useState(0);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        const res = await AxiosInstance.get(`/faqs/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id, refetch]);

  const [creating, setCreating] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleUpdate = async (e: any) => {
    e.preventDefault();
    try {
      setRefetch(0);
      setCreating(true);
      const res = await AxiosInstance.patch(`/faqs/${id}`, {
        question,
        answer,
      });
      res.data.success && toast.success(res.data.msg);
      setCreating(false);
      setRefetch(3);
    } catch (error: any) {
      console.error(error);
      setCreating(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (singleItem) {
      setQuestion(singleItem.question || "");
      setAnswer(singleItem.answer || "");
    }
  }, [singleItem]);

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4 flex flex-col gap-8">
          <Link
            to={"/dashboard/faqs"}
            className=" flex items-center gap-1  text-sm">
            <MoveLeft /> Back
          </Link>
          <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Edit Faq</h3>

          <form
            onSubmit={handleUpdate}
            className="">
            <div className="mt-1 text-sm leading-relaxed  text-gray-500">
              <div className=" flex flex-col  gap-4">
                <div>
                  <label className="font-medium text-gray-700 ">Question</label>
                  <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <label className="font-medium text-gray-700 ">Answer</label>
                  <textarea
                    defaultValue={singleItem?.answer}
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    className="w-full h-28  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>

                <div>
                  <span className=" text-transparent">.</span>
                  <button
                    disabled={creating}
                    type="submit"
                    className="w-full  p-2 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                    {creating ? <Spinner /> : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
