
import { useEffect, useState } from "react";
import { AxiosInstance } from "../config";

export default function Testimonials() {
  // fetch all
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/testimonials");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  console.log(fetching)

  return (
    <section className="mt-20">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h3 className="text-primary-500 font-semibold pb-6">What people are saying</h3>
          <ul>
            {comittees.map((item: any, idx) =>
              currentTestimonial === idx ? (
                <li key={idx}>
                  <figure>
                    <blockquote>
                      <p className="text-gray-700 text-xl font-medium sm:text-2xl">“{item.description}“</p>
                    </blockquote>
                    <div className="flex items-center justify-between flex-col  mt-4">
                      <p className=" bg-gray-100 h-10 w-10 rounded-full flex items-center justify-center font-medium text-gray-700 ">{item.name.charAt(0)}</p>
                      <div className="mt-3">
                        <span className="block text-gray-700 font-semibold">{item.name}</span>
                        <span className="block text-gray-600 text-sm mt-0.5">{item.title}</span>
                      </div>
                    </div>
                  </figure>
                </li>
              ) : (
                ""
              )
            )}
          </ul>
        </div>
        <div className="mt-6">
          <ul className="flex gap-x-3 justify-center">
            {comittees.map((item, idx) => (
              <li key={idx}>
                <button
                  className={`w-2.5 h-2.5 rounded-full duration-150 ring-offset-2 ring-primary-600 focus:ring ${currentTestimonial === idx ? "bg-primary-600" : "bg-gray-300"}`}
                  onClick={() => setCurrentTestimonial(idx)}></button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
