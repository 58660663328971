import { useState } from "react";
import logo from "../../assets/logo.png";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../config";

export default function Dashboard() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const res = await AxiosInstance.post("/admins/login", {
        email,
        password,
      });
      localStorage.setItem("accessToken", res.data.accessToken);
      res.data.success && toast.success(res.data.msg);
      res.data.success && navigate("/dashboard/doctors");
      window.location.reload();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <main className="w-full mt-20  flex flex-col items-center justify-center px-4">
      <div className="max-w-md w-full text-gray-600 space-y-5 shadow-md p-8">
        <div className="text-center pb-8">
          <img
            src={logo}
            width={150}
            className="mx-auto"
            alt="img"
          />
          <div className="mt-5">
            <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">Admin Login</h3>
          </div>
        </div>
        <form
          onSubmit={handleLogin}
          className="space-y-5">
          <div>
            <label className="font-medium">Email</label>
            <input
              type="email"
              required
              placeholder="example@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
            />
          </div>
          <div>
            <label className="font-medium">Password</label>
            <input
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Example Password"
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
            />
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 text-white font-medium bg-primary-600 hover:bg-primary-500 active:bg-primary-600 rounded-lg duration-150">
            Sign in
          </button>
        </form>
      </div>
    </main>
  );
}
