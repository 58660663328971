import nur17 from "../assets/nurse/nur17.jpg";
import icon1 from "../assets/doctors/icon1.jpg"
import icon2 from "../assets/doctors/icon2.jpg"
import icon3 from "../assets/doctors/icon3.jpg"

const Information = () => {
  return (
    <div className=" flex  justify-center items-center flex-col">
      <div className=" bg-gradient-to-tr from-slate-500 to-blue-600  relative ">
        <img
          className=" md:h-[100vh] h-[55vh]  md:w-[100vw] object-cover mix-blend-overlay"
          src={nur17}
          alt=""
        />
      </div>
      <div className=" z-20 md:-mt-40 absolute flex justify-center items-center md:w-9/12 w-11/12 flex-col gap-5">
        <div className=" flex gap-2">
          <h1 className="   cursor-pointer hover:animate-none   animate-bounce duration-700   px-4 py-2 text-6xl  text-white tracking-widest font-bold">
            Everest Hospital
          </h1>
        </div>
        <span className=" tracking-widest   md:text-5xl text-2xl    text-white z-10  italic font-bold">
          Everest Hospital offers you access to extensive and top-tier medical
          care.
        </span>
      </div>
      <div className=" z-40  -mt-28  bg-blue-500  flex justify-center">
        <div className="  md:flex justify-center  bg-primary-600 border-t-4 items-center w-11/12 md:w-10/12">
          {cares.map((care, index) => (
            <div key={index}>
              <div className=" flex flex-col gap-4 -mt-16 py-12 px-8 ">
                <div className="   flex items-center  justify-between py-5">
                  <span className="  text-gray-100 font-bold text-xl pt-5">
                    {care.work}
                  </span>
                  <img
                    className=" cursor-pointer object-cover h-16 w-16 rounded-full"
                    src={care.img}
                    alt=""
                  />
                </div>
                <span className=" md:flex hidden border border-gray-500  w-28"></span>
                <div className=" md:border-0 border-b-2 border-gray-500 py-2 text-gray-400 font-bold text-md">
                  {care.desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Information;

const cares = [
  {
    img: icon1,
    work: "Comfortable accommodations for patient care and recovery.",
    desc: "Everest Hospital: Central, accessible healthcare, affordable and integrated services. State-of-the-art facilities, experienced physicians create a warm environment for comprehensive care.",
  },
  {
    img: icon2,
    work: "Outpatient services for timely medical consultations.",
    desc: "Everest Hospital: Central, accessible healthcare, affordable and integrated services. State-of-the-art facilities, experienced physicians create a warm environment for comprehensive care.",
  },
  {
    img: icon3,
    work: "Informative sessions to enhance health.",
    desc: "Everest Hospital: Central, accessible healthcare, affordable and integrated services. State-of-the-art facilities experienced physicians create a warm environment for comprehensive care.",
  },
];
