import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { AxiosInstance } from "../config";

export default function Gallery() {
  // fetch all
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/galleries");
        setImages(res.data.data);
      } catch (error) {}
    };
    fetchAll();
  }, []);

  return (
    <>
      <section>
        <h3 className="text-primary-600 font-semibold mt-4 mb-8">Gallery</h3>
        <p className="w-full  lg:w-6/12 text-gray-600 mt-2 tracking-wide leading-normal">
          Explore our comprehensive gallery showcasing hospital facilities,
          dedicated staff, and impactful moments in healthcare excellence.
        </p>
        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-16">
          {defaultImages.map((image: string, index: number) => (
            <img
              key={index}
              src={image}
              alt="img"
              className=" hover:scale-110  duration-700"
              width={500}
              height={400}
            />
          ))}
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-16">
          {longSizeImages.map((image: string, index: number) => (
            <img
              data-aos="flip-left"
              data-aos-duration="1500"
              key={index}
              src={image}
              alt="img"
              className="image hover:scale-110 duration-700"
            />
          ))}
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-16">
          {images.map((item: any, index: number) => (
            <div
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              key={index}
              className="image-wrapper"
            >
              <img
                src={item?.image}
                alt="img"
                className="image hover:scale-110 duration-700"
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

const defaultImages = [
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766857/Everest/public/_DSC1720_frfrxn.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766856/Everest/public/_DSC1718_hb0kuk.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766856/Everest/public/_DSC1714_cp2qhv.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766855/Everest/public/_DSC1711_gjt1gn.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766855/Everest/public/_DSC1708_c4e0lu.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766854/Everest/public/_DSC1707_ih6atm.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766853/Everest/public/_DSC1697_sn8hb8.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766852/Everest/public/_DSC1696_ltiug6.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766852/Everest/public/_DSC1671_bnmq6g.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766851/Everest/public/_DSC1660_omghqh.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766849/Everest/public/_DSC1655_zvddin.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766846/Everest/public/_DSC1646_qpoeoj.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766843/Everest/public/_DSC1639_du1yp8.jpg",
  // "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766841/Everest/public/_DSC1629_pjsgkj.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766834/Everest/public/_DSC1611_kd5hh1.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766833/Everest/public/_DSC1603_igdpuq.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766832/Everest/public/_DSC1591_sumjet.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766830/Everest/public/_DSC1589_gtwfrq.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766828/Everest/public/_DSC1587_cnqso1.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766827/Everest/public/_DSC1582_peh9fr.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766824/Everest/public/_DSC1561_xsh8pu.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766822/Everest/public/_DSC1560_psuguo.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766812/Everest/public/_DSC1530_m1fza1.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766810/Everest/public/_DSC1529_mbgcje.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766811/Everest/public/_DSC1518_mpdnrx.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766809/Everest/public/_DSC1506_ogwb0n.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766808/Everest/public/_DSC1522_ysmwf0.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766808/Everest/public/_DSC1504_jgz5vn.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766806/Everest/public/039A6115_qvvplp.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766805/Everest/public/039A6121_zzftow.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766804/Everest/public/039A6106_ctw17w.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766801/Everest/public/039A6027_w3ll9f.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766800/Everest/public/039A5951_ozqfat.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766807/Everest/public/039A6138_etloci.jpg",
];

const longSizeImages = [
  // "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766839/Everest/public/_DSC1625_rhylwa.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766838/Everest/public/_DSC1623_ahtrn2.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766836/Everest/public/_DSC1617_yipz8h.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766835/Everest/public/_DSC1615_t2sehd.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766834/Everest/public/_DSC1607_js89h9.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766833/Everest/public/_DSC1606_jvsrfo.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766828/Everest/public/_DSC1584_luf4cy.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766826/Everest/public/_DSC1569_tigyhj.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766825/Everest/public/_DSC1565_vblmdo.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766824/Everest/public/_DSC1564_xr2aki.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766821/Everest/public/_DSC1558_w8gsld.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766820/Everest/public/_DSC1555_eylszb.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766819/Everest/public/_DSC1554_lusko6.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766819/Everest/public/_DSC1568_nwgrwq.jpg",

  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766816/Everest/public/_DSC1543_mcgmtr.jpg",
  "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766818/Everest/public/_DSC1553_xiehoc.jpg",
];
