import sam from "../assets/doctors/sampuna.jpg";
import prena from "../assets/doctors/prerna.jpg";
import naresh from "../assets/docImage/giri.jpg";
import bachhu from "../assets/doctors/bachhu.png";
import niraj from "../assets/doctors/nirajnep.png";
import roshan from "../assets/doctors/roshanphok.jpg";
import sunil from "../assets/doctors/sunilshit.png";
import rajan from "../assets/doctors/rajan.png";
import chandan from "../assets/doctors/Chandrasen.png";
import juoshna from "../assets/doctors/juotshna.png";

export default function Process() {
  return (
    <div className=" border-b border-gray-200">
      <section data-aos-duration="3000" className="py-14 ">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="max-w-xl mx-auto sm:text-center">
            <h3 className=" text-[#1BBDE4] text-xl font-bold  ">Our Process</h3>
            <p className="text-gray-800 text-3xl font-semibold sm:text-4xl mt-3">
              How it helps you stay strong
            </p>
          </div>
          <div className="mt-12">
            <ul className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4 ">
              {team.map((item, idx) => (
                <li key={idx}>
                  <div className="  group h-60 w-60 sm:h-52 md:h-72 md:w-72">
                    <img
                      src={item.image}
                      className="  border-2 border-gray-200   hover:scale-105 duration-700 relative w-full h-full object-cover object-center  rounded-full"
                      alt=""
                    />
                    <span className="group-hover:scale-105 duration-700  md:-mt-72   -mt-64  md:ml-52 ml-40  sm:-mt-56   absolute rounded-full px-4 py-2 bg-[#1BBDE4] text-gray-100 text-xl font-semibold">
                      {item.id}
                    </span>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg text-gray-700 font-semibold">
                      {item.name}
                    </h4>
                    <p className="text-[#AEB2BF] font-semibold">{item.title}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

const team = [
  {
    id: 1,
    image: chandan,
    name: "Prof. Dr. Chandeshwar Mahaseth",
    title: "Department : Pediatrics",
  },
  {
    id: 2,
    image: bachhu,
    name: "Prof. Dr. Bachhu Ram KC",
    title: "Department : Orthopedics",
  },

  {
    id: 3,
    image: naresh,
    name: "Prof. Dr. Naresh Giri",
    title: "Department : Surgery",
  },
  
  {
    id: 4,
    image: prena,
    name: "Dr. Prerna Jha",
    title: " We will conduct a thorough examination of health",
  },

  {
    id: 5,
    image: sam,
    name: "Dr. Sampurna Maharjan",
    title: "We will conduct a thorough examination of health",
  },
  {
    id: 6,
    image: juoshna,
    name: "Dr. Jyotshna Sharma ",
    title: "Department : Obstetrics and Gynecology",
  },

  {
    id: 7,
    image: rajan,
    name: "Dr. Rajan Shah",
    title: "Department : Gynecology",
  },
  {
    id: 8,
    image: sunil,
    name: "Dr. Sunil Shrestha",
    title: "Department : Gestroenterology and hepatology",
  },

  {
    id: 9,
    image: roshan,
    name: "Dr. Roshan Pokhrel",
    title: "Department : Anesthesiology",
  },
  {
    id: 10,
    image: niraj,
    name: "Dr. Niraj Nepal",
    title: "Department : Internal Medicine",
  },
];
