import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AxiosInstance } from "../config";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CalendarCheck, Clock, Stethoscope, Sun } from "lucide-react";
import toast from "react-hot-toast";
import parse from "html-react-parser";

export default function Appointment() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        const res = await AxiosInstance.get(`/doctors/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id]);
  console.log(singleItem);

  const [value, onChange] = useState<any>(new Date());

  const dayOfWeek = value?.getDay();
  const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const dayName = daysOfWeek[dayOfWeek];

  const isDayAvailable = !!(singleItem && singleItem[dayName] && singleItem[dayName].trim() !== "");

  const from = singleItem && singleItem[dayName]?.split("-")[0];
  const to = singleItem && singleItem[dayName]?.split("-")[1];
  const [selectedTime, setSelectedTime] = useState<any>();

  // / Assuming "from" and "to" are in the format 'hh:mmA'
  const fromTime = from && new Date(`2023-01-01T${from}`);
  const toTime = to && new Date(`2023-01-01T${to}`);
  const selectedTimeDate = selectedTime && new Date(`2023-01-01T${selectedTime}`);

  let isTimeAvailable = false;
  console.log(isTimeAvailable);

  if (fromTime && toTime && selectedTimeDate) {
    isTimeAvailable = selectedTimeDate >= fromTime && selectedTimeDate <= toTime;
  }

  const formatTime = (time24: string | undefined) => {
    if (!time24 || !/\d{2}:\d{2}/.test(time24)) {
      console.error("Invalid time format:", time24);
      return "Invalid time";
    }

    const [hours, minutes] = time24.split(":");
    if (isNaN(parseInt(hours, 10)) || isNaN(parseInt(minutes, 10))) {
      console.error("Invalid time values:", hours, minutes);
      return "Invalid time";
    }

    const formattedHours = parseInt(hours, 10) % 12 || 12;
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes} ${period}`;
  };

  const convertTo12HourFormat = (time: any) => {
    if (!time) {
      return "Not Available";
    }

    const [start, end] = time.split("-");
    const formatTime: any = (t: any) => {
      if (!t) {
        return "Not Available";
      }

      const [hours, minutes] = t.split(":");
      let formattedHours = parseInt(hours, 10);
      let period = formattedHours >= 12 ? "PM" : "AM";

      if (formattedHours > 12) {
        formattedHours -= 12;
      } else if (formattedHours === 0) {
        formattedHours = 12;
      }

      return `${formattedHours}:${minutes} ${period}`;
    };

    return `${formatTime(start)}-${formatTime(end)}`;
  };

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [booked, setBooked] = useState(false);
  const bookAppointment = async (e: any) => {
    e.preventDefault();
    try {
      const res = await AxiosInstance.post("/appointments", {
        doctor: id,
        day: dayName,
        time: selectedTime,
        fullName: fullName,
        phone: phone,
      });
      console.log(res);
      res.data.success && toast.success(res.data.msg);
      res.data.success && setFullName("");
      res.data.success && setPhone("");
      res.data.success && setSelectedTime("");
      res.data.success && setBooked(true);
    } catch (error: any) {
      console.log(error);
      setBooked(false);
    }
  };
  console.log(selectedTime);

  return (
    <div>
      <div className=" flex flex-col md:flex-row  gap-2 border px-2 py-4  md:p-8 bg-blue-50 rounded-sm  shadow-sm">
        <div className=" flex items-center justify-center">
          <img
            src={singleItem?.image}
            alt="img"
            className=" w-48 h-48 rounded-md object-cover"
          />
        </div>
        <div>
          <p className=" text-lg font-medium text-gray-700">{singleItem?.name}</p>
          <p className=" text-gray-600 mb-2">{singleItem?.position}</p>

          <p className=" text-sm font-medium text-gray-600 ">Available Time : </p>

         {singleItem?.availableWholeWeek==="yes" ? <div>
            <p className=" text-sm text-gray-600">Everyday 10:00 AM- 6:00 PM (Sunday - Friday) </p>
          </div>
:
          <div>
            {singleItem?.sunday === "" && singleItem?.monday === "" && singleItem?.tuesday === "" && singleItem?.wednesday === "" && singleItem?.thursday === "" && singleItem?.friday === "" ? (
              <p className="text-sm text-gray-500 my-2">On Call </p>
            ) : (
              <div className=" flex flex-wrap w-full lg:w-6/12 text-sm text-gray-500 gap-1 mb-2">
                <p className="mr-4">Sun: {singleItem?.sunday ? convertTo12HourFormat(singleItem.sunday) : "Not Available"}</p>
                <p className="mr-4">Mon: {singleItem?.monday ? convertTo12HourFormat(singleItem.monday) : "Not Available"}</p>
                <p className="mr-4">Tue: {singleItem?.tuesday ? convertTo12HourFormat(singleItem.tuesday) : "Not Available"}</p>
                <p className="mr-4">Wed: {singleItem?.wednesday ? convertTo12HourFormat(singleItem.wednesday) : "Not Available"}</p>
                <p className="mr-4">Thu: {singleItem?.thursday ? convertTo12HourFormat(singleItem.thursday) : "Not Available"}</p>
                <p className="mr-4">Fri: {singleItem?.friday ? convertTo12HourFormat(singleItem.friday) : "Not Available"}</p>
                <p className="mr-4">Sat: {singleItem?.saturday ? convertTo12HourFormat(singleItem.saturday) : "Not Available"}</p>
              </div>
            )}
          </div>}

          {/* <Link
            to={`/consultants/${id}`}
            className="px-4 py-1 text-xs text-white bg-primary-500 rounded-sm duration-150 hover:bg-white hover:text-primary-600 border hover:border-primary-600 transition-all ease-out ">
            View Doctor Detail
          </Link> */}
        </div>
      </div>

      {singleItem?.onlineAppointment === "yes"  &&  singleItem?.availableWholeWeek==="no" && (
        <div className="  mt-8 border shadow-sm bg-blue-50 p-8 rounded-sm">
          <form
            onSubmit={bookAppointment}
            className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3  gap-4">
            <div className=" flex justify-center">
              <Calendar
                onChange={onChange}
                value={value}
              />
            </div>
            <div className="">
              <input
                className=" w-full p-2 rounded-sm border border-primary-300 text-gray-600 cursor-pointer"
                type="time"
                name="appt"
                required
                onChange={(e) => setSelectedTime(e.target.value)}
                value={selectedTime}
              />

              <input
                className=" mt-4 w-full p-2 rounded-sm border border-primary-300 text-gray-600 cursor-pointer"
                type="text"
                required
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
                placeholder="Full Name"
              />

              <input
                className=" mt-4 w-full p-2 rounded-sm border border-primary-300 text-gray-600 cursor-pointer"
                type="number"
                required
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />

              <button
                type="submit"
                disabled={!isTimeAvailable || !isDayAvailable}
                className={`px-4 py-3 mt-4 w-full text-xs text-white rounded-sm duration-150 border transition-all ease-out bg-primary-500 hover:border-primary-600}`}>
                Book Appointment
              </button>
            </div>

            {isDayAvailable && isTimeAvailable && (
              <div className=" flex flex-col gap-2">
                <p className=" text-lg font-medium text-gray-700 flex items-center gap-2">
                  <CalendarCheck /> Your Appointment Detail
                </p>
                <p className="flex items-center gap-2 text-gray-600">
                  <Stethoscope size={16} /> with {singleItem?.name}{" "}
                </p>
                {dayName && (
                  <p className="flex items-center gap-2 text-gray-600">
                    <Sun size={16} /> {dayName}
                  </p>
                )}
                {selectedTime && (
                  <p className="flex items-center gap-2 text-gray-600">
                    <Clock size={16} /> {formatTime(selectedTime)}{" "}
                  </p>
                )}
              </div>
            )}

            <div className=" flex flex-col gap-3">
              <>
                {booked ? (
                  <p className=" bg-green-200  p-4 text-gray-600 rounded-sm font-medium">Appointment booking success</p>
                ) : (
                  <>
                    {!isDayAvailable && <p className="bg-red-500 text-white px-4 py-2 rounded-sm">Doctor is not Available on this day</p>}
                    {selectedTime !== undefined && !isTimeAvailable && <p className="bg-red-500 text-white px-4 py-2 rounded-sm">Doctor is not available at {formatTime(selectedTime)} ,</p>}
                  </>
                )}
              </>
            </div>
          </form>
        </div>
      )}

      <div className=" bg-blue-50 mt-4 p-4">
        <p className=" text-gray-700 text-justify">{parse(singleItem?.description || "")} </p>
      </div>
    </div>
  );
}
