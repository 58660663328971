import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { AxiosInstance } from "../config";

export default function SingleDepartment() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  // console.log(id);

  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        const res = await AxiosInstance.get(`/departments/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id]);

  const [doctorDepartments, setDoctorDepartments] = useState([]);
  useEffect(() => {
    const fetAllDoctors = async () => {
      try {
        const res = await AxiosInstance.get(
          `/doctors-departments/doctors-by-department/${id}`
        );
        setDoctorDepartments(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetAllDoctors();
  }, [id]);
  // console.log(doctorDepartments);

  return (
    <div>
      {/* <Link
        to={"/departments"}
        className=" flex items-center gap-1  text-sm mb-4">
        <MoveLeft /> Back
      </Link> */}
      <div className=" flex justify-center p-2  lg:p-8">
        <div className=" ">
          <img
            src={singleItem?.image}
            alt="img"
            className=" object-cover w-full h-32 shadow-sm rounded-md "
          />
        </div>
      </div>
      <div className=" flex flex-col  items-center mt-2 ">
        <p className=" text-center text-xl font-medium  text-primary-500 tracking-wider">
          {singleItem?.name}
        </p>
      </div>

      <div className=" flex flex-col lg:flex-row gap-4 shadow-sm py-4 px-2">
        <div className=" w-full">
          <p className=" font-medium mb-2 text-primary-600">
            {" "}
            {singleItem?.name}
          </p>
          <p className=" text-gray-700 text-justify">
            {parse(singleItem?.description || "")}
          </p>
        </div>

        <div className="w-full">
          <p className=" text-xl font-medium mb-4 text-center text-gray-700">
            Our Doctors
          </p>
          <div className="  grid grid-cols-2 gap-4">
            {doctorDepartments?.map((item: any) => (
              <div
                key={item._id}
                className=" bg-gray-100 p-1 rounded-md border border-primary-50"
              >
                <div className="relative h-16 ">
                  <img
                    src={item.doctor?.image}
                    alt="img"
                    className="object-contain w-full h-full "
                  />
                </div>

                <div className=" p-2">
                  <p className=" text-gray-700 text-sm font-medium">
                    {item.doctor?.name}
                  </p>
                  <p className=" text-gray-600 text-xs">
                    ({item?.doctor.position})
                  </p>
                  <p className=" text-gray-500 text-xs">
                    Department : {item?.doctor.department}
                  </p>

                  <div className=" flex flex-col gap-2 justify-between mt-4">
                    <Link
                      to={`/appointment/${item.doctor._id}`}
                      className="px-4 py-1 text-xs text-white bg-primary-500 rounded-sm duration-150 hover:bg-white hover:text-primary-600 border hover:border-primary-600 transition-all ease-out "
                    >
                      Appointment
                    </Link>

                    <Link
                      to={`/consultants/${item.doctor._id}`}
                      className="px-4 py-1 text-xs   rounded-sm duration-150 border border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white transition-all ease-out  "
                    >
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className=" mt-8 shadow-md p-4 md:p-8">
        <p className=" text-gray-600 tracking-wide leading-relaxed ">{parse(singleItem?.description || "")} </p>
      </div> */}

      {/* <div className=" ">
        <h3 className="text-primary-600 font-semibold mt-4 mb-8">Doctors that handles this department</h3>
        <section className=" ">
          <div className="border-b border-gray-200"></div>
          <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8">
            {doctorDepartments?.map((item: any, index: number) => (
              <div
                key={item._id}
                className=" bg-gary-100 shadow-md border p-1 rounded-md border-primary-50">
                <div className="relative h-48 ">
                  <img
                    src={item.doctor?.image}
                    alt="img"
                    className="object-contain w-full h-full "
                  />
                </div>
                <div className=" p-2">
                  <p className=" text-gray-700 font-medium">{item.doctor?.name}</p>
                  <p className=" text-gray-600">{item.doctor?.position}</p>
                  <Link
                    to={`/consultants/${item?.doctor?._id}`}
                    className="  text-xs text-end underline text-primary-500">
                    Doctor Detail
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div> */}
    </div>
  );
}
