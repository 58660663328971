import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { MoveLeft } from "lucide-react";
import { AxiosInstance } from "../config";

export default function SingleDoctor() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        const res = await AxiosInstance.get(`/doctors/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id]);

  return (
    <div>
      <Link
        to={"/consultants"}
        className=" flex items-center gap-1  text-sm mb-4">
        <MoveLeft /> Back
      </Link>
      <div className="shadow-md p-4 w-full  md:w-5/12 mx-auto">
        <div className=" flex justify-center   p-8">
          <img
            src={singleItem?.image}
            alt="img"
            className=" rounded-md"
            width={200}
            height={200}
          />
        </div>
        <div className=" flex flex-col  items-center mt-2 ">
          <p className=" text-center text-xl font-medium  text-primary-500 tracking-wider">{singleItem?.name}</p>
          <p className=" text-center text-lg  text-gray-600 tracking-wider">({singleItem?.position})</p>
          <p className=" text-center text-lg  text-gray-600 tracking-wider">Department : {singleItem?.department}</p>
          {singleItem?.nmc && <p className=" text-center text-lg  text-gray-600 tracking-wider">NMC No : {singleItem?.nmc}</p>}
        </div>
      </div>

      <div className=" mt-8 shadow-md p-8">
        <div className=" flex">
          <p className=" text-xl font-medium   tracking-wider mb-8 bg-primary-500 text-white p-2 rounded-sm">{singleItem?.name}</p>
        </div>
        <p className=" text-gray-700 text-justify">{parse(singleItem?.description || "")} </p>
      </div>

      <Link
        to={"/consultants"}
        className=" flex items-center gap-1  text-sm mt-8 justify-center">
        <MoveLeft /> Back
      </Link>
    </div>
  );
}
