import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";
import LeftBar from "../../components/dashboard/LeftBar";
import { formatDate } from "../../helper/formatDate";
import { AxiosInstance } from "../../config";

export default function DashVideo() {
  const { register, handleSubmit, watch, reset } = useForm();
  const allInputField = watch();
  const [refetch, setRefetch] = useState(0);

  const [creating, setCreating] = useState(false);
  const handleCreate = async () => {
    try {
      setCreating(true);
      const res = await AxiosInstance.post("/videos", allInputField);
      res.data.success && toast.success(res.data.msg);
      setCreating(false);
      reset();
      setRefetch(1);
    } catch (error: any) {
      console.error(error);
      setCreating(false);
      toast.error(error.message);
    }
  };

  //delete
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async (id: string) => {
    try {
      setDeleting(true);
      const res = await AxiosInstance.delete(`/videos/${id}`);
      res.data.success && toast.success(res.data.msg);
      setDeleting(false);
      setRefetch(2);
    } catch (error) {
      setDeleting(false);
    }
  };

  // fetch all
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setRefetch(0);
        setFetching(true);
        const res = await AxiosInstance.get("/videos");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, [refetch]);

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>
        <div className="w-10/12 shadow-md p-4">
          <div className=" ">
            <div className="items-start justify-between md:flex">
              <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Videos</h3>
              <div className="mt-3 md:mt-0">
                {/* Create Model start---------------------------------------------- */}
                <Dialog.Root>
                  <div className=" flex">
                    <Dialog.Trigger className="w-32 py-2 ml-2 shadow-sm rounded-md bg-primary-600 text-white mt-4 flex items-center justify-center">New Video</Dialog.Trigger>
                  </div>

                  <Dialog.Portal>
                    <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
                    <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-5xl mx-auto px-4">
                      <form
                        onSubmit={handleSubmit(handleCreate)}
                        className="bg-white rounded-md shadow-lg px-4 py-6">
                        <Dialog.Title className="text-lg font-medium text-gray-800 text-center mt-3"> Create New Video</Dialog.Title>
                        <Dialog.Description className="mt-1 text-sm leading-relaxed  text-gray-500">
                          <div className=" flex flex-col  gap-8">
                            <div>
                              <label className="font-medium text-gray-700 ">Vido URL</label>
                              <input
                                type="text"
                                required
                                placeholder="Paste URL here from youtube (Embed Url)"
                                {...register("url", { required: true })}
                                className="w-full  px-3 py-2 text-gray-600 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                              />
                            </div>
                          </div>
                        </Dialog.Description>

                        <div className="items-center gap-2 mt-14 text-sm sm:flex">
                          <div className=" w-6/12">
                            <button
                              disabled={creating}
                              type="submit"
                              className="w-full mt-2 p-2.5 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                              {creating ? <Spinner /> : "Create"}
                            </button>
                          </div>
                          <Dialog.Close
                            asChild
                            className=" w-full">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-primary-600 focus:ring-2"
                              aria-label="Close">
                              Cancel
                            </button>
                          </Dialog.Close>
                        </div>
                      </form>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
                {/* Model end-------------------------------------------------------- */}
              </div>
            </div>
            <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
              <table className="w-full table-auto text-sm text-left">
                <thead className=" text-gray-600 font-medium border-b">
                  <tr>
                    <th className="py-3 px-6">S.N</th>
                    <th className="py-3 px-6">URL</th>
                    <th className="py-3 px-6">Created At</th>
                    <th className="py-3 px-6">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 divide-y">
                  {comittees?.map((item: any, idx: any) => (
                    <tr key={idx}>
                      <td className="px-6 py-4 whitespace-nowrap">{idx + 1} .</td>

                      <td className="px-6 py-4 whitespace-nowrap">{item.url}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{formatDate(item.createdAt)}</td>
                      <td className=" px-6 whitespace-nowrap">
                        <button
                          onClick={() => handleDelete(item._id)}
                          disabled={item.deleting}
                          className="py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg">
                          {deleting ? <Spinner /> : "Delete"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className=" flex items-center justify-center my-5">{fetching && <Spinner />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
