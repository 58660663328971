import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../config";
import parse from "html-react-parser";
import Spinner from "../components/Spinner";

export default function Departments() {
  const [departments, setDepartments] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  const [departmentDoctors, setDepartmentDoctors] = useState<any>([]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/departments");
        setDepartments(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };

    const fetchAllDepartmentDoctors = async () => {
      try {
        const res = await AxiosInstance.get(`/doctors-departments`);
        setDepartmentDoctors(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAll();
    fetchAllDepartmentDoctors();
  }, []);

  console.log(departments);
  console.log(departmentDoctors);

  return (
    <div className="">
      <h3 className="text-primary-600 font-semibold mt-4 mb-8">Departments</h3>

      <div>
        {departments?.map((item: any, index: number) => (
          <div key={item._id} className=" flex gap-4 mb-20">
            <div className=" w-full">
              <p className=" font-medium mb-2 text-primary-600">
                {" "}
                {index + 1}. {item.name}
              </p>
              <p className=" text-gray-700">{parse(item.description)}</p>
            </div>

            <div className=" w-full">
              <h4 className=" text-2xl font-medium text-gray-700 text-center mb-4">
                Our Doctors
              </h4>
              <div className=" grid grid-cols-3 gap-4">
                {departmentDoctors
                  .filter((doc: any) => doc.department._id === item._id)
                  .map((doc: any) => (
                    <div
                      key={doc._id}
                      className=" bg-gray-100 p-1 rounded-md border border-primary-50"
                    >
                      <div className="relative h-16 ">
                        <img
                          src={doc.doctor?.image}
                          alt="img"
                          className="object-contain w-full h-full "
                        />
                      </div>

                      <div className=" p-2">
                        <p className=" text-gray-700 text-sm font-medium">
                          {doc.doctor?.name}
                        </p>
                        <p className=" text-gray-600 text-xs">
                          ({doc?.doctor.position})
                        </p>
                        <p className=" text-gray-500 text-xs">
                          Department : {item?.name}
                        </p>

                        <div className=" flex flex-col gap-2 justify-between mt-4">
                          <Link
                            to={`/appointment/${item._id}`}
                            className="px-4 py-1 text-xs text-white bg-primary-500 rounded-sm duration-150 hover:bg-white hover:text-primary-600 border hover:border-primary-600 transition-all ease-out "
                          >
                            Appointment
                          </Link>

                          <Link
                            to={`/consultants/${item._id}`}
                            className="px-4 py-1 text-xs   rounded-sm duration-150 border border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white transition-all ease-out  "
                          >
                            View Detail
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {fetching && (
        <div className=" flex items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
