import {
  BookA,
  CalendarCheck2,
  CalendarDays,
  ChevronDown,
  ChevronUp,
  Menu,
  Newspaper,
  Phone,
  Plane,
  ScrollText,
  Siren,
  Stethoscope,
  Trophy,
  X,
} from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import logo from "../assets/logoev.jpg";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { CharityContext } from "../context/context";
import { AxiosInstance } from "../config";

export default function Navbar() {
  const [state, setState] = useState(false);
  const [drapdownState, setDrapdownState] = useState<any>({
    isActive: false,
    idx: null,
  });
  const location = useLocation();
  const pathname = location.pathname;
  const dashboard = "dashboard";
  const dashboardPath = pathname.split("/")[1];
  const isDashboard = dashboard === dashboardPath;
  console.log(isDashboard);

  const navigation = [
    { title: "Home", path: "/", isDrapdown: false },
    { title: "Consultants", path: "/consultants", isDrapdown: false },
    { title: "Emergency", path: "/", isDrapdown: true },
    { title: "Contact", path: "/contact", isDrapdown: false },
    { title: "Health Packages", path: "/health-packages", isDrapdown: false },
  ];

  const navigation1 = [
    { title: "Accrediations", path: "/accrediations", isDrapdown: false },
    { title: "Awards", path: "/awards", isDrapdown: false },
    { title: "Notice", path: "/notices", isDrapdown: false },
    { title: "Events", path: "/events", isDrapdown: false },
    { title: "Lab Report", path: "https://labreport.merodoctor.com/666", isDrapdown: false },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target: any = e.target;
      if (!target.closest(".nav-menu"))
        setDrapdownState({ isActive: false, idx: null });
    };
  }, []);

  const modal1Ref = useRef<any>();
  const [openDepartment1, setopenDepartment1] = useState(false);

  const handleOutsideClick1: any = (event: React.MouseEvent) => {
    if (
      modal1Ref.current &&
      !modal1Ref.current.contains(event.target as Node)
    ) {
      setopenDepartment1(false);
    }
  };

  useEffect(() => {
    if (openDepartment1) {
      document.addEventListener("mousedown", handleOutsideClick1);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick1);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
    };
  }, [openDepartment1]);

  const [showTopBar, setShowTopBar] = useState(true);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setShowTopBar(scrollPosition <= 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { contactDetail } = useContext(CharityContext);

  // const [showDepartment, setshowDepartment] = useState(false);
  // const handleDepartment = () => {
  //   setshowDepartment(!showDepartment);
  // };

  // const [showDepartment, setshowDepartment] = useState(false);
  // const departmentRef = useRef<any>(null);

  // useEffect(() => {
  //   const handleClickOutside = (event:any) => {
  //     if (departmentRef.current && !departmentRef.current.contains(event.target)) {
  //       setshowDepartment(false);
  //     }
  //   };

  //   const handleEscapeKey = (event:any) => {
  //     if (event.key === "Escape") {
  //       setshowDepartment(false);
  //     }
  //   };

  //   if (showDepartment) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //     document.addEventListener("keydown", handleEscapeKey);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //     document.removeEventListener("keydown", handleEscapeKey);
  //   };
  // }, [showDepartment]);

  // const handleDepartment = () => {
  //   setshowDepartment(!showDepartment);
  // }

  const [showDepartment, setshowDepartment] = useState(false);
  const departmentRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        departmentRef.current &&
        !departmentRef.current.contains(event.target)
      ) {
        setshowDepartment(false);
      }
    };

    const handleEscapeKey = (event: any) => {
      if (event.key === "Escape") {
        setshowDepartment(false);
      }
    };

    if (showDepartment) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showDepartment]);

  const handleDepartment = () => {
    setshowDepartment(!showDepartment);
  };

  // fetch all
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/departments/less-data");
        setcomittees(res.data.data);
      } catch (error) {}
    };
    fetchAll();
  }, []);

  console.log(comittees);

  const [openSmallDeviceOverview, setOpenSmallDeviceOverview] = useState(false);
  const handleSmallDeviceOverview = () => {
    setOpenSmallDeviceOverview(!openSmallDeviceOverview);
  };

  const [openSmallDeviceGallery, setOpenSmallDeviceGallery] = useState(false);
  const handleSmallDeviceGallery = () => {
    setOpenSmallDeviceGallery(!openSmallDeviceGallery);
  };

  return (
    <>
      <nav
        className={`relative  z-20 bg-primary-500  w-full md:static md:text-sm md:border-none ${
          state ? "shadow-lg  md:shadow-none" : ""
        }`}
      >
        {showTopBar && (
          <div className="top_nav_bar flex items-center justify-between bg-white ">
            {contactDetail?.administrationNumber && (
              <div className=" flex flex-col bg-blue-600 bgop  px-4 py-2 w-full">
                <p className=" font-medium text-gray-100 text-xs ">Hospital</p>
                <a
                  href={`tel:${contactDetail?.administrationNumber}`}
                  className="text-gray-200 text-xs gap-1 flex"
                >
                  <Phone size={14} /> {contactDetail?.administrationNumber}
                  {}
                </a>
              </div>
            )}

            {contactDetail?.emergencyHotline && (
              <div className=" flex flex-col bg-red-600 px-4 py-2 w-full">
                <p className=" font-medium text-gray-100 text-xs">Emergency</p>
                <a
                  href={`tel:${contactDetail?.emergencyHotline}`}
                  className="text-gray-200 text-xs flex  gap-1"
                >
                  <Phone size={14} /> {contactDetail?.emergencyHotline}
                </a>
              </div>
            )}

            <div className=" flex flex-col  bg-green-700 px-4 py-2 w-full">
              <p className=" font-medium text-gray-100 text-xs"> Appointment</p>
              <a
                href={`tel:${contactDetail?.appointmentNumber}`}
                className="text-gray-200 text-xs flex  gap-1"
              >
                <span>
                  {" "}
                  <CalendarCheck2 size={14} />
                </span>{" "}
                {contactDetail?.appointmentNumber}
              </a>
            </div>

            <div className=" hidden md:block w-full">
              <Link
                to={"/accrediations"}
                className=" flex flex-col  bg-cyan-700  px-4 py-2  cursor-pointer"
              >
                <p className=" font-medium text-gray-100 ">
                  <Newspaper size={16} />
                </p>
                <p className="text-gray-200 text-xs">Acceediations</p>
              </Link>
            </div>

            <div className=" hidden md:block w-full">
              <Link
                to={"/awards"}
                className=" flex flex-col bg-blue-600 px-4 py-2  cursor-pointer"
              >
                <p className=" font-medium text-gray-100">
                  <Trophy size={16} />
                </p>
                <p className="text-gray-200 text-xs">Awards </p>
              </Link>
            </div>

            <div className=" hidden md:block w-full">
              <Link
                to={"/events"}
                className=" flex flex-col bg-purple-600 bgop  px-4 py-2  cursor-pointer"
              >
                <p className=" font-medium text-gray-100 ">
                  <CalendarDays size={16} />
                </p>
                <p className="text-gray-200 text-xs">Events</p>
              </Link>
            </div>

            <div className=" hidden md:block w-full">
              <Link
                to={"/notices"}
                className=" flex flex-col bg-teal-800 px-4 py-2  cursor-pointer"
              >
                <p className=" font-medium text-gray-100">
                  <ScrollText size={16} />{" "}
                </p>
                <p className="text-gray-200 text-xs">Notice</p>
              </Link>
            </div>

            <div className=" hidden md:block w-full">
              <Link
                target="_blank"
                to={"https://labreport.merodoctor.com/666"}
                className=" flex flex-col   bg-orange-700 px-4 py-2  cursor-pointer"
              >
                <p className=" font-medium text-gray-100">
                  <ScrollText size={16} />{" "}
                </p>
                <p className="text-gray-200 text-xs">Lab Report </p>
              </Link>
            </div>
          </div>
        )}
        <div className=" items-center  gap-x-14 px-4 sm:px-8 mx-auto md:flex  border-b  ">
          <div className="flex items-center justify-between py-2 md:block">
            <Link to={"/"} className=" flex gap-2 justify-center items-center">
              <img src={logo} alt="logo" className=" h-9  rounded-md" />
              <h1 className=" text-gray-100  italic">
                EVEREST HOSPITAL PVT.LTD
              </h1>
            </Link>
            <div className="md:hidden">
              <button
                className="text-gray-200"
                onClick={() => setState(!state)}
              >
                {state ? <X size={26} /> : <Menu size={26} />}
              </button>
            </div>
          </div>
          <div
            className={`nav-menu flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="items-center space-y-6 md:flex md:space-x-6 md:space-y-0 h-96 md:h-8 overflow-y-scroll md:overflow-hidden">
              <div className=" hidden md:block">
                <div className="dropdown inline-block ">
                  <button className=" text-gray-200   rounded inline-flex items-center">
                    <span className="mr-1 whitespace-nowrap">
                      Hospital Overview
                    </span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </button>

                  <ul className="  dropdown-menu absolute hidden text-gray-200  bg-primary-500  pt-4  pb-2">
                    {overviews?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div className=" block md:hidden">
                <p
                  onClick={handleSmallDeviceOverview}
                  className=" text-gray-300 font-medium flex items-center gap-1"
                >
                  Hospital Overview <ChevronDown size={16} />{" "}
                </p>
                {openSmallDeviceOverview && (
                  <div className=" text-gray-200  bg-primary-500  pt-4  pb-2">
                    {overviews?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {navigation.map((item: any, idx: any) => {
                return (
                  <li key={idx}>
                    {item.isDrapdown ? (
                      <button
                        className="w-full flex items-center justify-between gap-1 text-gray-200 "
                        onClick={() =>
                          setDrapdownState({
                            idx,
                            isActive: !drapdownState.isActive,
                          })
                        }
                      >
                        {item.title}
                        {drapdownState.idx === idx && drapdownState.isActive ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )}
                      </button>
                    ) : (
                      <a
                        href={item.path}
                        className={`${
                          pathname === item.path ? "underline  font-medium" : ""
                        } block text-gray-200 `}
                      >
                        {item.title}
                      </a>
                    )}
                    {item.isDrapdown &&
                    drapdownState.idx === idx &&
                    drapdownState.isActive ? (
                      <div className="mt-6 inset-x-0 top-14 w-full md:absolute bg-primary-500  md:border-y md:shadow-md md:mt-0">
                        <ul className="max-w-screen-xl mx-auto grid items-center gap-6 md:p-8 md:grid-cols-2 lg:grid-cols-3">
                          <div>
                            <div className="flex gap-3 items-center">
                              <Siren size={18} className=" text-gray-100" />
                              <div className=" flex flex-col">
                                <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                  Emergency Hotline
                                </span>
                                <a
                                  href={`tel:${contactDetail?.emergencyHotline}`}
                                  className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                >
                                  {contactDetail?.emergencyHotline}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="flex gap-3 items-center">
                              <Siren size={18} className=" text-gray-100" />
                              <div className=" flex flex-col">
                                <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                  Emergency Ambulance Hotline
                                </span>
                                <a
                                  href={`tel:${contactDetail?.emergencyAmbulanceHotline}`}
                                  className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                >
                                  {contactDetail?.emergencyAmbulanceHotline}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="flex gap-3 items-center">
                              <Phone size={18} className=" text-gray-100" />
                              <div className="flex flex-col">
                                <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                  Hospital Number
                                </span>
                                <a
                                  href={`tel:${contactDetail?.hospitalNumber}`}
                                  className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                >
                                  {contactDetail?.hospitalNumber}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="flex gap-3 items-center">
                              <Plane size={18} className=" text-gray-100" />
                              <div className=" flex flex-col">
                                <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                  Heli Rescue Number
                                </span>
                                {contactDetail?.heliRescueNumber && (
                                  <a
                                    href={`tel:${contactDetail?.heliRescueNumber}`}
                                    className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                  >
                                    {contactDetail?.heliRescueNumber}
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="flex gap-3 items-center">
                              <BookA size={18} className=" text-gray-100" />
                              <div className=" flex flex-col">
                                <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                  Appointmnt Number
                                </span>
                                <a
                                  href={`tel:${contactDetail?.appointmentNumber}`}
                                  className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                >
                                  {contactDetail?.appointmentNumber}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="flex gap-3 items-center">
                              <Stethoscope
                                size={18}
                                className=" text-gray-100"
                              />
                              <div className=" flex flex-col">
                                <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                  Adminintration Number
                                </span>
                                <a
                                  href={`tel:${contactDetail?.administrationNumber}`}
                                  className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                >
                                  {contactDetail?.administrationNumber}
                                </a>
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}

              <div className=" hidden md:block">
                <div className="dropdown inline-block ">
                  <button className=" text-gray-200    rounded inline-flex items-center">
                    <span className="mr-1 whitespace-nowrap">Gallery</span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </button>
                  <ul className="dropdown-menu absolute hidden text-gray-200  bg-primary-500  pt-4  pb-2">
                    {galleries?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div className=" block md:hidden">
                <p
                  onClick={handleSmallDeviceGallery}
                  className=" text-gray-300 font-medium flex items-center gap-1"
                >
                  Gallery <ChevronDown size={16} />{" "}
                </p>
                {openSmallDeviceGallery && (
                  <div className=" text-gray-200  bg-primary-500  pt-4  pb-2">
                    {galleries?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <button
                onClick={handleDepartment}
                className=" flex text-gray-200 items-center"
              >
                {" "}
                Departments <ChevronDown size={16} />
              </button>

              {openDepartment1 && (
                <div className="mt-6 inset-x-0  flex relative md:absolute bg-primary-500 w-52 right-28 ">
                  <ul className="flex flex-col gap-2   right-56 ">
                    <Link to={"/about"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        {" "}
                        About
                      </p>
                    </Link>
                    <Link to={"/executive-comittee"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        Executive Comittee
                      </p>
                    </Link>
                    <Link to={"/accrediations"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        {" "}
                        Accrediations
                      </p>
                    </Link>
                    <Link to={"/awards"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        {" "}
                        Awards & Recognition
                      </p>
                    </Link>
                  </ul>
                </div>
              )}

              <div
                className={` nav-menu flex-1 pb-3 mt-8  md:pb-0 md:mt-0 ${
                  state ? "block" : "hidden"
                }`}
              >
                <ul className="items-center space-y-6 md:flex md:space-x-6 md:space-y-0  md:h-8 overflow-y-scroll md:overflow-hidden">
                  {navigation1.map((item: any, idx: any) => {
                    return (
                      <li key={idx}>
                        {item.isDrapdown ? (
                          <button
                            className="w-full flex items-center justify-between gap-1 text-gray-200 "
                            onClick={() =>
                              setDrapdownState({
                                idx,
                                isActive: !drapdownState.isActive,
                              })
                            }
                          >
                            {item.title}
                            {drapdownState.idx === idx &&
                            drapdownState.isActive ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            )}
                          </button>
                        ) : (
                          <a
                            href={item.path}
                            className={`${
                              pathname === item.path
                                ? "underline  font-medium"
                                : ""
                            } block text-gray-200 `}
                          >
                            {item.title}
                          </a>
                        )}
                        {item.isDrapdown &&
                        drapdownState.idx === idx &&
                        drapdownState.isActive ? (
                          <div className="mt-6 inset-x-0 top-14 w-full md:absolute bg-primary-500  md:border-y md:shadow-md md:mt-0">
                            <ul className="max-w-screen-xl mx-auto grid items-center gap-6 md:p-8 md:grid-cols-2 lg:grid-cols-3">
                              <div>
                                <div className="flex gap-3 items-center">
                                  <Siren size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                      Emergency Hotline
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.emergencyHotline}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.emergencyHotline}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Siren size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                      Emergency Ambulance Hotline
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.emergencyAmbulanceHotline}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.emergencyAmbulanceHotline}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Phone size={18} className=" text-gray-100" />
                                  <div className="flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                      Hospital Number
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.hospitalNumber}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.hospitalNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Plane size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                      Heli Rescue Number
                                    </span>
                                    {contactDetail?.heliRescueNumber && (
                                      <a
                                        href={`tel:${contactDetail?.heliRescueNumber}`}
                                        className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                      >
                                        {contactDetail?.heliRescueNumber}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <BookA size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                      Appointmnt Number
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.appointmentNumber}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.appointmentNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Stethoscope
                                    size={18}
                                    className=" text-gray-100"
                                  />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium md:text-base">
                                      Adminintration Number
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.administrationNumber}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.administrationNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </ul>
          </div>
        </div>

        {showDepartment && (
          <div
            ref={departmentRef}
            className="fixed top-12 bg-primary-500 w-screen p-4"
            style={{ width: "100vw" }}
          >
            <div className=" flex">
              <p className=" text-gray-200 font-semibold text-xl mb-4 bg-blue-500 p-2 rounded-sm">
                Our Departments
              </p>
            </div>
            <div className=" grid grid-cols-1 sm-grid-cols-2 lg:grid-cols-4 gap-2 h-52 overflow-y-scroll">
              {comittees?.map((item: any) => (
                <Link
                  to={`/departments/${item._id}`}
                  onClick={handleDepartment}
                  key={item._id}
                  className=" flex flex-col"
                >
                  <p className=" text-gray-200 hover:bg-gray-300 p-2 cursor-pointer hover:text-gray-800 hover:font-medium transition-all ease-in-out hover:tracking-wide">
                    {item.name}
                  </p>
                  <hr className=" opacity-20" />
                </Link>
              ))}
            </div>
          </div>
        )}
      </nav>
      {state ? (
        <div
          className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
          onClick={() => setState(false)}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}

const overviews = [
  {
    title: "About Everest",
    href: "/about",
  },

  {
    title: "Our Mission & Vision",
    href: "/our-mission-vision",
  },
  {
    title: "Our Core Values",
    href: "/our-core-values",
  },

  {
    title: "Executive Comittee",
    href: "/executive-comittee",
  },

  {
    title: "Our Facilities",
    href: "/our-facilities",
  },
];

const galleries = [
  {
    title: "Image",
    href: "/image",
  },

  {
    title: "Video",
    href: "/video",
  },
];
