import { User, CalendarDays,Video,Package, Target,ScrollText, Newspaper, Network, Trophy, ShieldQuestion, Phone, Image, UsersRound, Aperture, Info, Shapes, HeartPulse, BookHeart, CalendarCheck } from "lucide-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";


export default function LeftBar() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[2];
  const capitalizedPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);

  return (
    <>
      <div>
        {links.map((item: any, index: any) => (
          <Link
            to={item.href}
            key={index}
            className={`${item.title === capitalizedPathname ? "bg-primary-500 text-white" : " hover:bg-gray-200"} text-gray-700  flex items-center gap-1  cursor-pointer p-2 rounded-sm transition-all ease-in-out font-medium`}>
            {React.createElement(item.icon)}
            <p className=" text-sm ">{item.title}</p>
          </Link>
        ))}
      </div>
    </>
  );
}

const links = [
  {
    title: "Departments",
    icon: Network,
    href: "/dashboard/departments",
  },
  {
    title: "Doctors",
    icon: User,
    href: "/dashboard/doctors",
  },

  {
    title: "Events",
    icon: CalendarDays,
    href: "/dashboard/events",
  },

  {
    title: "Notices",
    icon: ScrollText,
    href: "/dashboard/notices",
  },

  {
    title: "Accrediations",
    icon: Newspaper,
    href: "/dashboard/accrediations",
  },

  {
    title: "Awards",
    icon: Trophy,
    href: "/dashboard/awards",
  },

  {
    title: "Faqs",
    icon: ShieldQuestion,
    href: "/dashboard/faqs",
  },

  {
    title: "Contacts",
    icon: Phone,
    href: "/dashboard/contacts",
  },

  {
    title: "Galleries",
    icon: Image,
    href: "/dashboard/galleries",
  },

  {
    title: "Comittees",
    icon: UsersRound,
    href: "/dashboard/comittees",
  },

  {
    title: "Features",
    icon: Aperture,
    href: "/dashboard/features",
  },

  {
    title: "Abouts",
    icon: Info,
    href: "/dashboard/abouts",
  },

  {
    title: "Facilities",
    icon: Shapes,
    href: "/dashboard/facilities",
  },

  {
    title: "Values",
    icon: HeartPulse,
    href: "/dashboard/values",
  },

  {
    title: "Testimonials",
    icon: BookHeart,
    href: "/dashboard/testimonials",
  },

  {
    title: "Appointments",
    icon: CalendarCheck,
    href: "/dashboard/appointments",
  },

  {
    title: "Packages",
    icon: Package,
    href: "/dashboard/packages",
  },

  {
    title: "Vision",
    icon: Target,
    href: "/dashboard/vision",
  },



  {
 
    title: "Video",
    icon: Video,
    href: "/dashboard/video",
  },
];
